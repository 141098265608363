import React, { useState } from "react";
import AppBar from "./AppBar";
// import Footer from "./Footer";
import { Backdrop, makeStyles } from "@material-ui/core";
import AppDrawer from "./Drawer";
import { createCookie, readCookie } from "../../util/cookies";

const Layout = ({ children }) => {
  const classes = useStyels();
  const drawerCookie = readCookie("drawer");
  const initDrawer = drawerCookie ? +drawerCookie > 0 : window.innerWidth > 790;
  const [drawer, setDrawer] = useState(initDrawer);
  const toggleDrawer = () => {
    setDrawer((prev) => {
      createCookie("drawer", prev ? 0 : 1);
      return !prev;
    });
  };

  return (
    <div className={classes.root}>
      <AppDrawer open={drawer} setDrawer={toggleDrawer} />
      <Backdrop
        className={classes.bd}
        open={drawer}
        transitionDuration={500}
        onClick={toggleDrawer}
        style={{ zIndex: 99 }}
      />
      <AppBar drawer={drawer} setDrawer={toggleDrawer} />
      <main className={classes.content} id="main">
        {children}
      </main>
      {/* <Footer /> */}
    </div>
  );
};

const useStyels = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  bd: {
    zIndex: 99,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    // overflow: "hidden",
    width: "calc(100% - 250px)",
    paddingTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      // paddingLeft: theme.spacing(8),
    },
  },
}));
export default Layout;
