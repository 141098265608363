import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.LANGUAGE_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.LANGUAGE_ERRORS,
    errors: err,
  };
};

export const getLanguages = () => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = `/languages`;

      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_LANGUAGES, languages: res.data.data });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const getLanguage = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/languages/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addEditLanguage = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(itemLoading());

    try {
      let url = "/languages";

      let res;

      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }

      dispatch({
        type: actionTypes.SET_SUPPORTED_LANGS,
        languages: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchLanguageStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/languages/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.LANGUAGE_SWITCH, id, status });
      dispatch({
        type: actionTypes.SET_SUPPORTED_LANGS,
        languages: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteLanguage = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/languages/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.LANGUAGE_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
