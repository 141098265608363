import axiosAuth from "axios";
import Globals from "./globals";

export const axios = axiosAuth.create({
  baseURL: `${Globals.server}/v1`,
  withCredentials: true,
  // timeout: 10000,
  // headers: {
  //   // Expires: "0",
  //   "Cache-Control": "no-cache, no-store",
  //   Control: "must-revalidate max-age=0",
  // },
});

export const setAuthHeader = (data) => {
  return new Promise((resolve) => {
    let token = data?.token || "";
    let locale = data?.locale || "ar";
    if (!token) {
      // console.log("removed header");
      delete axios.defaults.headers.common["Authorization"];
      return resolve();
    }
    // console.log("Added header: " + token);

    axios.defaults.headers.common["Authorization"] = `Bearer ${token || ""}`;
    axios.defaults.headers.common["locale"] = locale;
    return resolve();
  });
};

export const setLocaleAuthHeader = (locale) => {
  // console.log(`SET AUTH LOCALE ${locale}`);
  axios.defaults.headers.common["locale"] = locale;
};
