import Globals from "../../util/globals";
import * as actionsTypes from "../actions/types";

const initialState = {
  events: [],
  resources: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.EVENT_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_EVENTS:
      const events = action.events.map((e) => {
        return {
          ...e,
          color: whichColor(e.status),
        };
      });
      return {
        ...state,
        events: events,
        resources: action.resources,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.ADD_EVENT:
      return {
        ...state,
        loading: false,
        events: [
          ...state.events,
          {
            ...action.event,
            start: new Date(action.event.start),
            end: new Date(action.event.end),
            color: whichColor(action.event.status),
          },
        ],
      };
    case actionsTypes.EDIT_EVENT:
      return {
        ...state,
        loading: false,
        events: state.events.map((e) =>
          e.event_id === action.event.event_id
            ? {
                ...action.event,
                start: new Date(action.event.start),
                end: new Date(action.event.end),
                color: whichColor(action.event.status),
              }
            : e
        ),
      };
    case actionsTypes.EVENT_DELETE:
      return {
        ...state,
        loading: false,
        events: state.events.filter((q) => q.event_id !== action.id),
      };
    case actionsTypes.EVENT_SWITCH:
      const { id, status } = action;
      const updatedStatusEvents = state.events.map((_event) => {
        if (_event.event_id === id) {
          return {
            ..._event,
            status: status,
          };
        }
        return _event;
      });
      return {
        ...state,
        loading: false,
        events: updatedStatusEvents,
      };

    case actionsTypes.EVENT_HISTORY:
      return {
        ...state,
        events: state.events.map((e) => {
          if (e.event_id === action.id) {
            return {
              ...e,
              history: [...e.history, action.history],
            };
          }
          return e;
        }),
      };
    case actionsTypes.EVENT_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    default:
      return state;
  }
};

const whichColor = (status) => {
  return status === Globals.EVENT_STATUS.DONE
    ? Globals.EVENT_COLORS.DONE
    : status === Globals.EVENT_STATUS.POSTPONED
    ? Globals.EVENT_COLORS.POSTPONED
    : null;
};
export default reducer;
