import React from "react";

const Taxonomies = React.lazy(() => import("../views/Taxonomy/Taxonomy"));
const AddEditTaxonomy = React.lazy(() => import("../views/Taxonomy/AddEdit"));

const taxonomyRoutes = [
  {
    path: ["/store/taxonomy/:type", "/media/taxonomy/:type"],
    component: Taxonomies,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: [
      "/store/taxonomy/:type/add",
      "/store/taxonomy/:type/edit/:id",
      "/media/taxonomy/:type/add",
      "/media/taxonomy/:type/edit/:id",
    ],
    component: AddEditTaxonomy,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
];

export default taxonomyRoutes;
