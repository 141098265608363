import React, { useState } from "react";
import { Slide, useTheme } from "@material-ui/core";
import LoginSquare from "./LoginSquare";
import ResetPasswordSquare from "./ResetPasswordSquare";
import WithRecaptcha from "../../components/common/WithRecaptcha";

const Login = () => {
  const [mode, setMode] = useState("login");
  const theme = useTheme();

  return (
    <WithRecaptcha>
      <div
        className={`${theme.css.gradient_primary} fullheightVH overflow_hidden`}
      >
        <Slide
          in={mode === "login"}
          direction="left"
          timeout={{
            enter: 400,
            exit: 200,
          }}
          // appear={mode === "login"}
          unmountOnExit
        >
          <LoginSquare onSwitchMode={setMode} />
        </Slide>

        <Slide
          in={mode === "reset"}
          direction="right"
          timeout={{
            enter: 400,
            exit: 200,
          }}
          // appear={mode === "reset"}
          unmountOnExit
          exit={true}
        >
          <ResetPasswordSquare onSwitchMode={setMode} />
        </Slide>
      </div>
    </WithRecaptcha>
  );
};

export default Login;
