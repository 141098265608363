import React from "react";

const Library = React.lazy(() => import("../views/Media/Library"));
const Gallery = React.lazy(() => import("../views/Media/Gallery/Gallery"));
const GalleryAddEdit = React.lazy(() =>
  import("../views/Media/Gallery/AddEdit")
);

const mediaRoutes = [
  {
    path: "/media/library",
    component: Library,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/media/gallery",
    component: Gallery,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: ["/media/gallery/add", "/media/gallery/edit/:id"],
    component: GalleryAddEdit,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
];

export default mediaRoutes;
