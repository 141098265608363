import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import i18n from "../../i18n";
import Globals from "../../util/globals";
import NotifIcon from "./NotifIcon";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

const drawerWidth = Globals.drawerWidth;

const HeaderBar = ({ history, drawer, setDrawer }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    try {
      await dispatch(actions.logout());
      history.replace("/");
    } catch (err) {
      //
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link to="/profile">
        <MenuItem>{t("common:my_profile")}</MenuItem>
      </Link>
      <MenuItem onClick={handleLogout}>{t("common:logout")}</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link to="/profile">
        <MenuItem>{t("common:my_profile")}</MenuItem>
      </Link>
      <MenuItem onClick={handleLogout}>{t("common:logout")}</MenuItem>
    </Menu>
  );
  const BackIcon =
    i18n.dir(i18n.language) === "rtl" ? ChevronRightIcon : ChevronLeftIcon;

  return (
    <>
      <AppBar
        className={`${classes.appBar} ${drawer ? classes.appBarShift : ""}`}
        // position="relative"
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawer()}
          >
            <div className="flex">
              <MenuIcon
                className={
                  drawer ? classes.menuButtonOpen : classes.menuButtonClose
                }
                fontSize="large"
              />
              <BackIcon
                className={
                  drawer ? classes.arrowButtonOpen : classes.arrowButtonClose
                }
                fontSize="large"
              />
            </div>
          </IconButton>
          <div className={classes.grow} />
          <NotifIcon />
          <div className={classes.sectionDesktop}>
            <Button
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              startIcon={<AccountCircle />}
              endIcon={<ExpandMoreRoundedIcon />}
              size="small"
            >
              <Typography className={classes.title} variant="h6" noWrap>
                {auth.name}
              </Typography>
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    padding: 8,
  },
  menuButtonOpen: {
    transform: "rotateZ(90deg)",
    opacity: 0,
    width: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
    }),
  },
  menuButtonClose: {
    transform: "rotateZ(0)",
    opacity: 1,
    width: "auto",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
    }),
  },
  arrowButtonOpen: {
    transform: "rotateZ(0)",
    opacity: 1,
    // marginLeft: "-50%",
    width: "auto",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
    }),
  },
  arrowButtonClose: {
    transform: "rotateZ(-90deg)",
    opacity: 0,
    width: 0,
    // marginLeft: "-50%",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
    }),
  },
  title: {
    // display: "none",
    // [theme.breakpoints.up("sm")]: {
    //   display: "block",
    // },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
    }),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default withRouter(HeaderBar);
