import React, { useState } from "react";
import {
  Container,
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Fade,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Input from "../../components/Inputs/Input";
import i18n from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { fireSnake, requestEmailResetPass } from "../../store/actions";
import ButtonSpinner from "../../components/UI/Spinners/ButtonSpinner";
import LanguageSwitch from "../../components/common/LanguageSwitch";

const initialState = {
  email: {
    value: "",
    validity: false,
    label: i18n.t("common:email"),
  },
};

const ResetPasswordSquare = React.forwardRef(({ onSwitchMode }, ref) => {
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  const handleInputResult = (value, validity, name) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: { ...state[name], value, validity },
      };
    });
  };

  const handleSendEmail = async () => {
    try {
      const email = state.email.value;
      await dispatch(requestEmailResetPass(email));
      dispatch(fireSnake("success", i18n.t("common:pass_reset_email_success")));
      handleInputResult("", false, "email");
    } catch (err) {
      // dispatch(fireSnake("error", err.message));
    }
  };

  const canSubmit = state.email.validity;

  return (
    <Container className="fullheight" ref={ref}>
      <Fade in timeout={1500}>
        <Box className="fullheight flex__centerlize">
          <Grid container alignContent="center">
            <Grid item md={4} sm={3} xs={1} />
            <Grid item md={4} sm={6} xs={12}>
              <Paper className="p-1" elevation={3}>
                <Typography variant="h4" align="center">
                  {t("common:reset_pass")}
                </Typography>
                <Box className="p" />
                <Input
                  label={t("common:email")}
                  name="email"
                  value={state.email.value}
                  getResult={handleInputResult}
                  required
                  onKeyDown={(k) =>
                    canSubmit && k.key === "Enter" ? handleSendEmail() : null
                  }
                  email
                  margin={4}
                />
                {auth.errors && (
                  <Typography
                    color="error"
                    variant="subtitle2"
                    className="py center"
                  >
                    {auth.errors.message}
                  </Typography>
                )}
                <Box className="center py-1">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!canSubmit || auth.loading}
                    className="width60"
                    onClick={canSubmit ? handleSendEmail : null}
                    endIcon={auth.loading && <ButtonSpinner />}
                  >
                    {t("common:reset_password")}
                  </Button>
                </Box>

                <Button
                  color="primary"
                  disabled={auth.loading}
                  onClick={() => onSwitchMode("login")}
                >
                  <Typography variant="caption">
                    {t("common:return_login")}
                  </Typography>
                </Button>
                <LanguageSwitch color="secondary" />
              </Paper>
            </Grid>
            <Grid item md={4} sm={3} xs={1} />
          </Grid>
        </Box>
      </Fade>
    </Container>
  );
});

export default ResetPasswordSquare;
