import React from "react";

const FlagIcon = ({ code, name, width = 24, height = 16, isLang }) => {
  let _code = code;
  if (code === "ar" && isLang) {
    _code = "sa";
  }

  if (!code) return null;
  return (
    <figure style={{ display: "flex", margin: "0 2px" }}>
      <img
        src={`/images/flags/${_code}.png`}
        alt={name || `Flag of ${_code}`}
        width={width}
        height={height}
      />
    </figure>
  );
};

export default FlagIcon;
