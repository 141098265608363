import * as actionsTypes from "../../actions/types";

const initialState = {
  coupons: {},
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.COUPONS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_COUPONS:
      return {
        ...state,
        coupons: {
          coupons: action.coupons.coupons,
          pagination: action.coupons.pagination,
        },
        loading: false,
      };
    case actionsTypes.COUPONS_SWITCH:
      const currCoupons = state.coupons.coupons;
      const id = action.id;
      const status = action.status;
      const updatedStatusCoupons = currCoupons.map((coup) => {
        if (coup.coupon_id === id) {
          return {
            ...coup,
            status: status,
          };
        }
        return coup;
      });
      return {
        ...state,
        coupons: {
          ...state.coupons,
          coupons: updatedStatusCoupons,
        },
        itemLoading: false,
      };
    case actionsTypes.COUPONS_DELETE:
      const thisCoupon = state.coupons.coupons;
      const filteredCoupons = thisCoupon.filter(
        (coup) => coup.coupon_id !== action.id
      );
      return {
        ...state,
        coupons: {
          ...state.coupons,
          coupons: filteredCoupons,
        },
        itemLoading: false,
      };
    case actionsTypes.COUPONS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
