import * as actionsTypes from "../../actions/types";

const initialState = {
  products: {},
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.PRODUCTS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case actionsTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.products,
        loading: false,
      };
    case actionsTypes.PRODUCT_SWITCH:
      return {
        ...state,
        products: {
          ...state.products,
          products: state.products.products.map((prod) => {
            if (prod.product_id === action.id) {
              return {
                ...prod,
                status: action.status,
              };
            }
            return prod;
          }),
        },
        itemLoading: false,
      };

    case actionsTypes.DELETE_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          products: state.products.products.filter(
            (prod) => prod.product_id !== action.id
          ),
        },
        itemLoading: false,
      };
    case actionsTypes.PRODUCTS_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    default:
      return state;
  }
};
export default reducer;
