import React, { useReducer, useEffect } from "react";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import ThemeContext from "./themeContext";
import reducer, { getPalette, getCSSThemeBG } from "./themeReducer";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import i18next from "../i18n";

const initialState = {
  palette: getPalette("main"),
  typography: {
    fontFamily: ["Cairo", "Roboto"].join(","),
    h1: {
      fontFamily: ["Cairo-bold", "Roboto"].join(","),
      fontSize: "2rem",
      letterSpacing: "-0.007em",
    },
    h2: {
      fontFamily: ["Cairo-bold", "Roboto"].join(","),
      fontSize: "1.8rem",
      letterSpacing: "-0.01em",
      // [breakpoints.up("md")]: {
      //   fontSize: "5rem",
      // },
    },
    h3: {
      fontFamily: ["Cairo-semi", "Roboto"].join(","),
      fontSize: "1.5rem",
      letterSpacing: "-0.01em",
    },
    h4: {
      fontFamily: ["Cairo-semi", "Roboto"].join(","),
      fontSize: "1.2rem",
      letterSpacing: "-0.01em",
    },
    h6: {
      fontFamily: ["Cairo-semi", "Roboto"].join(","),
      fontSize: "1rem",
      letterSpacing: "-0.01em",
    },
  },
  direction: "rtl",
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: "0.8rem",
      },
    },
    MuiAutocomplete: {
      option: {
        "&:hover": { background: "#8ea2b3" },
      },
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: 1,
        },
      },
      root: {
        "& .MuiInputLabel-root": {
          // transform: `translate(${14}px, ${12}px) scale(1)`,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: 10,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: `translate(${14}px, ${8}px) scale(1)`,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.65rem",
        marginTop: 0,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "0.9rem",
      },
    },
    MuiDialog: {
      root: {
        // zIndex: "1500!important",
      },
      paperFullWidth: {
        maxWidth: "85%",
      },
    },
    MuiInput: {
      underline: {
        "&$disabled": {
          "&:before": { borderBottomStyle: "solid" },
        },
      },
    },
    MuiTableCell: {
      body: {
        fontFamily: ["Cairo", "Roboto"].join(","),
      },
    },
  },
  css: {
    ...getCSSThemeBG("main"),
  },
};

const ThemeState = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const theme = responsiveFontSizes(createTheme(state));
  const auth = useSelector((state) => state.auth);

  // const toggleTheme = (tempName) => {
  //   dispatch({ type: "SET_PALETTE", template: tempName });
  // };
  // useEffect(() => {
  //   if (template) {
  //     toggleTheme(template.value);
  //   }
  // }, [template]);
  const toggleLanguage = (value) => {
    let lang = "ar";
    let dir = "rtl";
    if (value === "en") {
      lang = "en";
      dir = "ltr";
    }
    dispatch({ type: "TOGGLE_LANG", lang: lang, dir: dir, value });
    i18next.changeLanguage(value);
  };

  useEffect(() => {
    if (i18next.language && i18next.language !== auth.locale) {
      toggleLanguage(i18next.language);
    }
  }, [auth.locale]);

  // const toggleDarkMode = () => {
  //   if (state.palette.type === "light") {
  //     dispatch({
  //       type: "TOGGLE_DARK",
  //       payload: "dark",
  //     });
  //   } else {
  //     dispatch({
  //       type: "TOGGLE_DARK",
  //       payload: "light",
  //     });
  //   }
  // };

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        template: state.css.template,
        primary: state.css.primary,
        primary_light: state.css.primary_light,
        primary_light_color: state.css.primary_light_color,
        secondary: state.css.secondary,
        gradient_primary: state.css.gradient_primary,
        gradient_secondary: state.css.gradient_secondary,
        gradient_third: state.css.gradient_third,
        toggleLanguage,
        // toggleDarkMode,
      }}
    >
      <StylesProvider jss={jss}>{props.children}</StylesProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeState;
