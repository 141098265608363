import * as actionsTypes from "../actions/types";

const initialState = {
  taxonomies: {},
  loading: false,
  itemLoading: false,
  errors: null,
};
let flattedTaxos = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.TAXONOMY_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case actionsTypes.SET_TAXONOMY:
      flattedTaxos = [];
      const taxonomies = falttenTaxonomies(action.taxonomies.taxonomies);
      return {
        ...state,
        taxonomies: {
          taxonomies: taxonomies,
          pagination: action.taxonomies.pagination,
        },
        loading: false,
      };
    case actionsTypes.TAXONOMY_SWITCH:
      const currentTaxo = state.taxonomies.taxonomies;
      const id = action.id;
      const status = action.status;
      const updatedStatus = currentTaxo.map((taxo) => {
        if (taxo.taxonomy_id === id) {
          return {
            ...taxo,
            status: status,
          };
        }
        return taxo;
      });
      return {
        ...state,
        taxonomies: {
          ...state.taxonomies,
          taxonomies: updatedStatus,
        },
      };
    case actionsTypes.TAXONOMY_DELETE:
      const thisTaxos = state.taxonomies.taxonomies;
      const filteredTaxos = thisTaxos.filter(
        (taxo) => taxo.taxonomy_id !== action.id && taxo.parent_id !== action.id
      );
      return {
        ...state,
        taxonomies: {
          ...state.taxonomies,
          taxonomies: filteredTaxos,
        },
        itemLoading: false,
      };
    case actionsTypes.TAXONOMY_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};

let currentParent = "";
const falttenTaxonomies = (taxonomies, child) => {
  for (const taxo of taxonomies) {
    let taxonomy = { ...taxo };
    if (child) {
      taxonomy.title = currentParent + taxonomy.title;
    }
    flattedTaxos.push(taxonomy);
    if (taxo.children && taxo.children.length) {
      currentParent = taxo.title + " > ";
      falttenTaxonomies(taxo.children, true);
    }
  }
  return flattedTaxos;
};
export default reducer;
