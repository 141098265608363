import * as actionsTypes from "../actions/types";

const initialState = {
  entries: {},
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.ENTRY_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_ENTRY:
      return {
        ...state,
        entries: action.entries,
        loading: false,
      };
    case actionsTypes.ENTRY_DELETE:
      const _entries = state.entries.entries;
      const filteredEntries = _entries.filter(
        (ent) => ent.entry_id !== action.id
      );
      return {
        ...state,
        entries: {
          ...state.entries,
          entries: filteredEntries,
        },
        itemLoading: false,
      };
    case actionsTypes.ENTRY_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
