import React from "react";
import { Box, Button, Container, Paper, Typography } from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import { useTranslation } from "react-i18next";

const Page404 = ({ history }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Paper>
        <Box height={30} />
        <Typography variant="h2" align="center">
          خطأ 404 - Error 404
        </Typography>
        <Box padding={5} className="center">
          <WarningRoundedIcon
            color="error"
            style={{ fontSize: 200, opacity: 0.2 }}
          />
          <Typography variant="body2">{t("common:404_notice")}</Typography>

          <Box className="m">
            <Button
              onClick={() => history.replace("/")}
              color="primary"
              variant="outlined"
              startIcon={<HomeRoundedIcon />}
            >
              {t("common:back_home")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Page404;
