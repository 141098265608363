import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.TAX_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.TAX_ERRORS,
    errors: err,
  };
};

export const autocompleteTaxes = (q) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = "/tax/autocomplete?q=";
      if (q) {
        url += q;
      }
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_TAXES, taxes: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getTaxes = (query) => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = "/tax";
      // let counter = 1;
      // for (const key in query) {
      //   let operator = counter > 1 ? "&" : "?";
      //   url += `${operator}${key}=${query[key]}`;
      //   counter++;
      // }
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_TAXES, taxes: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const getTax = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/tax/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addEditTax = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(itemLoading());

    try {
      let url = "/tax";

      let res;

      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }

      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchTaxStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/tax/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.TAX_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteTax = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/tax/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.TAX_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
