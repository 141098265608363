import * as actionsTypes from "../../actions/types";

const initialState = {
  profile: null,
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_PROFILE:
      return {
        ...state,
        profile: action.profile,
        loading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
