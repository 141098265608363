import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.ORDERS_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.ORDERS_ERRORS,
    errors: err,
  };
};

export const getOrders = (query) => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      const url = `/orders` + query;
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_ORDERS, orders: res.data.data });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const getOrder = (id, query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/orders/${id}` + (query || "");
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addOrderHistory = (id, body) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      // const {status_id, comment,tracking,company} = body
      let url = `/orders/${id}/history`;
      const res = await axios.post(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getOrderStatuses = () => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/orders/statuses`;
      const res = await axios.get(url);

      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
// export const deleteOrder = (id) => {
//   return async (dispatch) => {
//     dispatch(itemLoading());
//     try {
//       let url = `/orders/${id}`;
//       const res = await axios.delete(url);
//       dispatch({ type: actionTypes.ORDERS_DELETE, id: id });
//       return res.data.data;
//     } catch (err) {
//       const error = err.response ? err.response.data : err;
//       dispatch(setErrors(error));
//       throw error;
//     }
//   };
// };
