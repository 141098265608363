import React, { useState, useRef } from "react";
import {
  Container,
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Fade,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Input from "../../components/Inputs/Input";
import i18n from "../../i18n";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../store/actions";
import ButtonSpinner from "../../components/UI/Spinners/ButtonSpinner";
import LanguageSwitch from "../../components/common/LanguageSwitch";

const initialState = {
  email: {
    value: "",
    validity: false,
    label: i18n.t("common:email"),
  },
  password: {
    value: "",
    validity: false,
    label: i18n.t("common:password"),
  },
};

const LoginSquare = React.forwardRef(({ onSwitchMode }, ref) => {
  const [state, setState] = useState(initialState);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const passRef = useRef();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  const handleInputResult = (value, validity, name) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: { ...state[name], value, validity },
      };
    });
  };

  const handleSignin = () => {
    const email = state.email.value;
    const password = state.password.value;
    dispatch(signin(email, password));
  };

  const canSubmit = state.email.validity && state.password.validity;

  return (
    <Container className="fullheight" ref={ref}>
      <Fade in timeout={1500}>
        <Box className="fullheight flex__centerlize">
          <Grid container alignContent="center">
            <Grid item md={4} sm={3} xs={1} />
            <Grid item md={4} sm={6} xs={12}>
              <Paper className="p-1" elevation={3}>
                <Typography variant="h2" align="center">
                  {t("common:login")}
                </Typography>
                <Box className="p" />
                <Input
                  label={t("common:email")}
                  name="email"
                  getResult={handleInputResult}
                  required
                  onKeyDown={(k) =>
                    k.key === "Enter" && passRef.current.focus()
                  }
                  margin={4}
                />

                <Input
                  label={t("common:password")}
                  name="password"
                  getResult={handleInputResult}
                  withIcon={
                    visible ? (
                      <VisibilityIcon
                        color="primary"
                        onClick={() => setVisible(!visible)}
                        className="hoverable-op"
                      />
                    ) : (
                      <VisibilityOffIcon
                        color="disabled"
                        onClick={() => setVisible(!visible)}
                        className="hoverable-op"
                      />
                    )
                  }
                  type={visible ? "text" : "password"}
                  onKeyDown={(k) =>
                    canSubmit && k.key === "Enter" ? handleSignin() : null
                  }
                  required
                  min={5}
                  reff={passRef}
                  margin={4}
                />
                {auth.errors && (
                  <Typography
                    color="error"
                    variant="subtitle2"
                    className="py center"
                  >
                    {auth.errors.message}
                  </Typography>
                )}
                <Box className="center py-1">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!canSubmit || auth.loading}
                    className="width60"
                    onClick={canSubmit ? handleSignin : null}
                    endIcon={auth.loading && <ButtonSpinner />}
                  >
                    {t("common:login")}
                  </Button>
                </Box>

                <Button
                  color="primary"
                  disabled={auth.loading}
                  onClick={() => onSwitchMode("reset")}
                >
                  <Typography variant="caption">
                    {t("common:forgot_pass")}
                  </Typography>
                </Button>
                <LanguageSwitch color="secondary" />
              </Paper>
            </Grid>
            <Grid item md={4} sm={3} xs={1} />
          </Grid>
        </Box>
      </Fade>
    </Container>
  );
});

export default LoginSquare;
