//Auth
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_ERRORS = "AUTH_ERRORS";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_LOCALE = "SET_LOCALE";

//Snakebar
export const FIRE_SNAKEBAR = "FIRE_SNAKEBAR";
export const CLEAR_SNAKEBAR = "CLEAR_SNAKEBAR";

//Products
export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const PRODUCTS_ERRORS = "PRODUCTS_ERRORS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const EDIT_PRODUCTS = "EDIT_PRODUCTS";
export const PRODUCT_SWITCH = "PRODUCT_SWITCH";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

//Specials
export const SPECIALS_LOADING = "SPECIALS_LOADING";
export const SPECIALS_ERRORS = "SPECIALS_ERRORS";
export const SET_SPECIALS = "SET_SPECIALS";

//Taxonomy
export const TAXONOMY_LOADING = "TAXONOMY_LOADING";
export const TAXONOMY_ERRORS = "TAXONOMY_ERRORS";
export const SET_TAXONOMY = "SET_TAXONOMY";
export const TAXONOMY_DELETE = "TAXONOMY_DELETE";
export const TAXONOMY_SWITCH = "TAXONOMY_SWITCH";

//Options
export const OPTIONS_LOADING = "OPTIONS_LOADING";
export const OPTIONS_ERRORS = "OPTIONS_ERRORS";
export const SET_OPTIONS = "SET_OPTIONS";
export const OPTIONS_DELETE = "OPTIONS_DELETE";
export const OPTIONS_SWITCH = "OPTIONS_SWITCH";

//Coupons
export const COUPONS_LOADING = "COUPONS_LOADING";
export const COUPONS_ERRORS = "COUPONS_ERRORS";
export const SET_COUPONS = "SET_COUPONS";
export const COUPONS_DELETE = "COUPONS_DELETE";
export const COUPONS_SWITCH = "COUPONS_SWITCH";

//Orders
export const ORDERS_LOADING = "ORDERS_LOADING";
export const ORDERS_ERRORS = "ORDERS_ERRORS";
export const SET_ORDERS = "SET_ORDERS";

//Customers
export const CUSTOMERS_LOADING = "CUSTOMERS_LOADING";
export const CUSTOMERS_ERRORS = "CUSTOMERS_ERRORS";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMER_GROUPS = "SET_CUSTOMER_GROUPS";
export const CUSTOMERS_SWITCH = "CUSTOMERS_SWITCH";

//Reviews
export const REVIEWS_LOADING = "REVIEWS_LOADING";
export const REVIEWS_ERRORS = "REVIEWS_ERRORS";
export const SET_REVIEWS = "SET_REVIEWS";
export const REVIEW_SWITCH = "REVIEW_SWITCH";

//Settings
export const SET_GENERALS = "SET_GENERALS";
export const SET_SHIPPINGS = "SET_SHIPPINGS";
export const SET_PAYMENTS = "SET_PAYMENTS";
export const SETTING_LOADING = "SETTING_LOADING";
export const SETTING_ERRORS = "SETTING_ERRORS";

//Tax
export const TAX_LOADING = "TAX_LOADING";
export const SET_TAXES = "SET_TAXES";
export const TAX_ERRORS = "TAX_ERRORS";
export const TAX_DELETE = "TAX_DELETE";
export const TAX_SWITCH = "TAX_SWITCH";

//Language
export const SET_LANGUAGES = "SET_LANGUAGES";
export const LANGUAGE_LOADING = "LANGUAGE_LOADING";
export const LANGUAGE_ERRORS = "LANGUAGE_ERRORS";
export const LANGUAGE_SWITCH = "LANGUAGE_SWITCH";
export const LANGUAGE_DELETE = "LANGUAGE_DELETE";

//Currency
export const SET_CURRENCIES = "SET_CURRENCIES";
export const CURRENCY_LOADING = "CURRENCY_LOADING";
export const CURRENCY_ERRORS = "CURRENCY_ERRORS";
export const CURRENCY_SWITCH = "CURRENCY_SWITCH";
export const CURRENCY_DELETE = "CURRENCY_DELETE";

//Admins
export const SET_ADMINS = "SET_ADMINS";
export const SET_NOTIFS = "SET_NOTIFS";
export const SET_TOP_NOTIFS = "SET_TOP_NOTIFS";
export const CLEAR_NOTIF = "CLEAR_NOTIF";
export const ADMINS_LOADING = "ADMINS_LOADING";
export const ADMINS_ERRORS = "ADMINS_ERRORS";
export const ADMINS_SWITCH = "ADMINS_SWITCH";
export const ADMINS_DELETE = "ADMINS_DELETE";

//Profile
export const SET_PROFILE = "SET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_ERRORS = "PROFILE_ERRORS";

// Media
export const MEDIA_LOADING = "MEDIA_LOADING";
export const MEDIA_ERRORS = "MEDIA_ERRORS";
export const SET_FILES = "SET_FILES";
export const SELECT_FILE = "SELECT_FILE";
export const SELECT_DIR = "SELECT_DIR";
export const CLEAR_SELECTED = "CLEAR_SELECTED";
export const DELETED_FILE = "DELETED_FILE";
export const ON_DROP = "ON_DROP";
export const ON_PROGRESS = "ON_PROGRESS";
export const FILE_UPLOADED = "FILE_UPLOADED";
export const UPLOADED_ERROR = "UPLOADED_ERROR";
export const SET_GALLERIES = "SET_GALLERIES";
export const GALLERY_DELETE = "GALLERY_DELETE";
export const GALLERY_SWITCH = "GALLERY_SWITCH";

// Events
export const EVENT_LOADING = "EVENT_LOADING";
export const EVENT_ERRORS = "EVENT_ERRORS";
export const SET_EVENTS = "SET_EVENTS";
export const EVENT_SWITCH = "EVENT_SWITCH";
export const EVENT_DELETE = "EVENT_DELETE";
export const ADD_EVENT = "ADD_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";
export const EVENT_HISTORY = "EVENT_HISTORY";
export const ACTION_EVENT = "ACTION_EVENT";
// Contact Entries
export const ENTRY_LOADING = "ENTRY_LOADING";
export const ENTRY_ERRORS = "ENTRY_ERRORS";
export const ENTRY_DELETE = "ENTRY_DELETE";
export const SET_ENTRY = "SET_ENTRY";

//Globals
export const LOADING = "LOADING";
export const ERRORS = "ERRORS";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_SUPPORTED_LANGS = "SET_SUPPORTED_LANGS";
export const APP_LOADING = "APP_LOADING";
export const ITEM_LOADING = "ITEM_LOADING";
export const UNLOAD = "UNLOAD";
