import React, { Fragment } from "react";
import {
  Avatar,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import IconTextNotice from "../../common/IconTextNotice";
import { useTranslation } from "react-i18next";
import TimelineRoundedIcon from "@material-ui/icons/TimelineRounded";

const RankList = ({ data, color, headline, wAvatar }) => {
  const { t } = useTranslation();
  return (
    <List dense>
      <Typography align="center" variant="h6">
        {headline}
      </Typography>
      {!data?.length ? (
        <IconTextNotice
          text={t("common:no_stats")}
          Icon={TimelineRoundedIcon}
          padding={1}
          fontSize={150}
        />
      ) : (
        data.map((v, i) => (
          <Fragment key={i}>
            <ListItem>
              <ListItemAvatar>
                {wAvatar ? (
                  <Avatar alt={v.title} src={v.image[0]?.path} />
                ) : (
                  <Avatar>{v.title.charAt(0)}</Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={v.title} secondary={v.subtitle} />
              <ListItemSecondaryAction>
                <Chip
                  label={v.value}
                  size="small"
                  color={color || "primary"}
                  //   variant="outlined"
                />
              </ListItemSecondaryAction>
            </ListItem>
            {i < data.length - 1 && <Divider variant="inset" />}
          </Fragment>
        ))
      )}
    </List>
  );
};

export default RankList;
