import React, { useState, useEffect, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { Typography } from "@material-ui/core";
import { TryNextInputFocus } from "../../util/generals";

const Input = ({
  variant,
  label,
  placeholder,
  value,
  name,
  required,
  min,
  max,
  email,
  type,
  decimal,
  multiPhone,
  getResult,
  disabled,
  serverError,
  multiline,
  rows,
  setTouched,
  htmlMin,
  htmlMax,
  mathMin,
  mathMax,
  maxErrMsg,
  minErrMsg,
  step,
  withIcon,
  withStartIcon,
  onKeyDown,
  inputStyles,
  fieldStyles,
  styles,
  className,
  inputClassName,
  autoFocus,
  reff,
  margin,
  helperNotice,
  shrink,
  inputMode,
  enterKeyHint,
  nextFocusName,
  id,
  selectOnFocus,
  otherProps,
  labelStyle,
}) => {
  const [state, setState] = useState({
    // value: initialValue ? initialValue : "",
    touched: false,
    valid: false,
    errorMsg: "",
  });
  const { t } = useTranslation();

  const showServerErr = useCallback(() => {
    setState((prev) => {
      return {
        ...prev,
        valid: false,
        touched: true,
        errorMsg: serverError,
      };
    });
  }, [serverError]);
  useEffect(() => {
    if (serverError) {
      showServerErr();
    }
  }, [serverError, showServerErr]);

  useEffect(() => {
    if (setTouched) {
      handleChange({ target: { value } });
    }
    // eslint-disable-next-line
  }, [setTouched]);
  const handleChange = (e) => {
    let val = e.target.value;
    let isValid = true;
    let errorMsg = "";
    if (email) {
      const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = reg.test(val) && isValid;
      errorMsg = t("common:email_err");
    }
    if (decimal) {
      const reg = /^-?[0-9]+(\.[0-9]*)?$/;
      isValid = reg.test(val) && isValid;
      errorMsg = t("common:onlynumbers");
    }
    if (multiPhone) {
      const reg = /^[+0-9]+(-?[0-9]\/?\+?)+$/;
      isValid = reg.test(val) && isValid;
      errorMsg = t("common:mobile_err");
    }
    if (required && min && `${val}`.trim().length <= min) {
      isValid = false;
      errorMsg = t("common:min_num_err", { min: min + 1 });
    }
    if (mathMin && +val <= +mathMin) {
      isValid = false;
      errorMsg = minErrMsg
        ? minErrMsg
        : t("common:math_min_err", { min: mathMin });
    }
    if (mathMax && +val > +mathMax) {
      isValid = false;
      errorMsg = maxErrMsg
        ? maxErrMsg
        : t("common:math_max_err", { max: mathMax });
    }
    if (required && max && `${val}`.trim().length >= max) {
      isValid = false;
      errorMsg = t("common:str_length_err", { max: max - 1 });
    }
    if (required && `${val}`.trim().length <= 0) {
      isValid = false;
      errorMsg = t("common:required_err");
    }
    // setState({ value: val, touched: true, valid: isValid, errorMsg: errorMsg });
    setState({ touched: true, valid: isValid, errorMsg: errorMsg });

    getResult(val, isValid, name);
  };

  return (
    <TextField
      {...otherProps}
      id={id ? `input_${id}` : `input_${name}`}
      variant={variant || "outlined"}
      label={
        !!label && (
          <Typography variant="body2">{`${label} ${
            required ? "**" : ""
          }`}</Typography>
        )
      }
      InputLabelProps={{
        shrink: shrink,
        style: labelStyle,
      }}
      value={value}
      name={name}
      onChange={handleChange}
      disabled={disabled}
      error={state.touched && !state.valid}
      helperText={
        (state.touched && !state.valid && state.errorMsg) || helperNotice
      }
      className={className}
      type={type}
      autoFocus={autoFocus}
      multiline={multiline}
      rows={rows}
      style={{ width: "100%", margin: margin || 0, ...styles }}
      onKeyDown={(e) => {
        if (onKeyDown) {
          onKeyDown(e);
        } else if (e.key === "Enter" && nextFocusName) {
          e.preventDefault();
          TryNextInputFocus(nextFocusName);
        }
      }}
      inputProps={{
        ...otherProps?.inputProps,
        min: htmlMin,
        max: htmlMax,
        step: step,
        "aria-label": label || placeholder,
        style: {
          ...inputStyles,
          ...((decimal || multiPhone || email) && i18n.language === "ar"
            ? inputARstyle
            : ""),
        },
        className: inputClassName,
        inputMode: decimal
          ? "numeric"
          : multiPhone
          ? "tel"
          : email
          ? "email"
          : type === "search"
          ? "search"
          : inputMode,
        enterKeyHint: nextFocusName ? "next" : enterKeyHint,
      }}
      InputProps={{
        ...otherProps?.InputProps,
        endAdornment: withIcon ? withIcon : "",
        startAdornment: withStartIcon ? withStartIcon : "",
        placeholder: placeholder ? t(`common:${placeholder}`) : "",
        style: fieldStyles,
      }}
      onFocus={(e) => {
        e.stopPropagation();
        if (selectOnFocus) {
          e.target.select();
        }
      }}
      inputRef={reff}
    />
  );
};

const inputARstyle = {
  // direction: "ltr",
  // textAlign: "right",
};

export default Input;
