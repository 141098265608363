import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.SETTING_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.SETTING_ERRORS,
    errors: err,
  };
};

export const getSettings = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = "/settings";
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_GENERALS, generals: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const saveSettings = (body) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = "/settings";
      const res = await axios.put(url, body);
      dispatch({ type: actionTypes.SET_GENERALS, generals: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

/*
 * Shippings
 */
export const getShippings = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = "/shippings";
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_SHIPPINGS, shippings: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const saveShippings = (body) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = "/shippings";
      const res = await axios.put(url, body);
      dispatch({ type: actionTypes.SET_SHIPPINGS, shippings: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
/**
 * Payments
 */
export const getPayments = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = "/payments";
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_PAYMENTS, payments: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const savePayments = (body) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = "/payments";
      const res = await axios.put(url, body);
      dispatch({ type: actionTypes.SET_PAYMENTS, payments: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
