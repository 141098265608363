import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.PROFILE_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.PROFILE_ERRORS,
    errors: err,
  };
};

export const getProfile = () => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = `/profile`;

      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_PROFILE, profile: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const updateProfile = (body) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/profile`;
      const res = await axios.put(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const changePassword = (body) => {
  return async (dispatch) => {
    dispatch(itemLoading());

    try {
      let url = "/profile";

      const res = await axios.patch(url, body);

      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
