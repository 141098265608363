import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import SwitchButtons from "../Buttons/SwitchButtons";
import i18n from "../../i18n";
import themeContext from "../../theme/themeContext";
import FlagIcon from "./FlagIcon";

const langBtns = [
  {
    id: "ar",
    title: <FlagIcon code="ar" name="عربي" isLang />,
  },
  {
    id: "en",
    title: <FlagIcon code="en" name="English" isLang />,
  },
];

const LanguageSwitch = ({ color, onChanged, orientation, fullWidth }) => {
  const { toggleLanguage } = useContext(themeContext);
  return (
    <Box className="center py">
      <SwitchButtons
        buttons={langBtns}
        value={i18n.language}
        color={color}
        onClick={(val) => {
          if (val !== i18n.language) {
            toggleLanguage(val);
            if (onChanged) {
              onChanged(val);
            }
          }
        }}
        size="medium"
        orientation={orientation}
        fullWidth={fullWidth}
      />
    </Box>
  );
};

export default LanguageSwitch;
