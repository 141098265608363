import * as actionsTypes from "../../actions/types";

const initialState = {
  options: {},
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.OPTIONS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_OPTIONS:
      return {
        ...state,
        options: {
          options: action.options.options,
          pagination: action.options.pagination,
        },
        loading: false,
      };
    case actionsTypes.OPTIONS_SWITCH:
      const currOptions = state.options.options;
      const id = action.id;
      const status = action.status;
      const updatedStatusAtts = currOptions.map((att) => {
        if (att.option_id === id) {
          return {
            ...att,
            status: status,
          };
        }
        return att;
      });
      return {
        ...state,
        options: {
          ...state.options,
          options: updatedStatusAtts,
        },
        itemLoading: false,
      };
    case actionsTypes.OPTIONS_DELETE:
      const thisOptions = state.options.options;
      const filteredOps = thisOptions.filter(
        (att) => att.option_id !== action.id
      );
      return {
        ...state,
        options: {
          ...state.options,
          options: filteredOps,
        },
        itemLoading: false,
      };
    case actionsTypes.OPTIONS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
