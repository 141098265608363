import React from "react";
import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { fireSnake } from "./globalsnakebar";
import Ttext from "../../components/common/Ttext";

const loading = () => {
  return {
    type: actionTypes.MEDIA_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.MEDIA_ERRORS,
    errors: err,
  };
};
export const preNavigateDir = () => {
  return (dispatch, getState) => {
    const { media } = getState();
    dispatch(clearAllSelected());
    dispatch({
      type: actionTypes.SET_FILES,
      files: {
        files: [],
        pagination: {
          totalCount: 1,
          currentPage: 1,
          perPage: 25,
          totalPages: 1,
        },
      },
      path: media.path || "",
    });
  };
};
let timeout;
export const getDir = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    // dispatch(clearAllSelected());
    clearTimeout(timeout);
    try {
      let url = `/media`;
      // const path = query.path || "";
      let counter = 1;
      for (const key in query) {
        let operator = counter > 1 ? "&" : "?";
        url += `${operator}${key}=${query[key] || ""}`;
        counter++;
      }
      const promise = new Promise((resolve) => {
        timeout = setTimeout(async () => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;

      dispatch({
        type: actionTypes.SET_FILES,
        files: res.data.data,
        path: query.path || "",
      });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      dispatch(fireSnake("error", error.message || "UNKNOWN ERROR"));
    }
  };
};
export const getFileInfo = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    clearTimeout(timeout);
    try {
      const url = `media/${id}`;
      const promise = new Promise((resolve) => {
        timeout = setTimeout(async () => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;

      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const updateFile = (id, body) => {
  return async (dispatch, getState) => {
    try {
      const { media } = getState();
      const url = `media/${id}`;
      const res = await axios.put(url, body);
      const newFile = res.data.data;
      const files = {
        ...media.files,
        files: media.files.files.map((f) =>
          +f.media_id === +newFile.media_id ? { ...f, ...newFile } : f
        ),
      };
      dispatch({
        type: actionTypes.SET_FILES,
        files: files,
        path: media.path,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const selectFile = (file, shift) => {
  if (!file) {
    return { type: "" };
  }

  if (file.isDir) {
    return {
      type: actionTypes.SELECT_DIR,
      title: file.title,
    };
  }
  return {
    type: actionTypes.SELECT_FILE,
    id: file.media_id,
  };
};
export const selectWithShift = (file) => {
  return (dispatch, getState) => {
    const { media } = getState();
    const thisId = file.isDir ? file.title : file.media_id;
    const firstSelected = media.selectedDirs[0] || media.selectedFiles[0];
    const files = media.files.files;
    const thisIdx = files.findIndex((f) => (f.media_id || f.title) === thisId);
    let tobeSelected = [];
    // let stepper = 0;
    if (!firstSelected) {
      tobeSelected = files.slice(0, thisIdx + 1);
    } else {
      const fromIdx = files.findIndex(
        (f) => (f.media_id || f.title) === firstSelected
      );
      // stepper = 1;
      tobeSelected = files.slice(fromIdx, thisIdx + 1);
    }
    dispatch(clearAllSelected());
    for (const _f of tobeSelected) {
      dispatch(selectFile(_f));
    }
  };
};
export const clearAllSelected = () => {
  return {
    type: actionTypes.CLEAR_SELECTED,
  };
};
export const selectAll = () => {
  return (dispatch, getState) => {
    const { media } = getState();
    const files = media.files.files;
    if (
      files.length !== [...media.selectedFiles, ...media.selectedDirs].length
    ) {
      dispatch(clearAllSelected());
    }
    for (const file of files) {
      dispatch(selectFile(file));
    }
  };
};
export const addFolder = (title) => {
  return async (dispatch, getState) => {
    try {
      const { media } = getState();
      const url = `media?path=${media.path}`;
      const res = await axios.post(url, { dirname: title });
      const newDir = {
        isDir: true,
        title: res.data.data,
      };
      const files = {
        ...media.files,
        files: [newDir, ...media.files.files],
      };
      dispatch({
        type: actionTypes.SET_FILES,
        files: files,
        path: media.path,
      });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const deleteFiles = () => {
  return async (dispatch, getState) => {
    try {
      const { media } = getState();
      let url = `media?path=${media.path}`;
      for (const dir of media.selectedDirs) {
        url += `&file=${dir}&type=dir`;
        await axios.delete(url);
        dispatch(afterDelete("dir", dir));
      }
      for (const file of media.selectedFiles) {
        url += `&file=${file}&type=file`;
        await axios.delete(url);
        dispatch(afterDelete("file", file));
      }
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
    }
  };
};

const afterDelete = (_type, file) => {
  return {
    type: actionTypes.DELETED_FILE,
    _type,
    file,
  };
};

export const onDrop = (files) => {
  return {
    type: actionTypes.ON_DROP,
    files,
  };
};
export const onProgress = (percentage, upload_id) => {
  return {
    type: actionTypes.ON_PROGRESS,
    percentage,
    upload_id,
  };
};
export const uploadFile = (file, direct = false, path) => {
  return async (dispatch, getState) => {
    try {
      const { media } = getState();
      const endpoint = direct ? "dupload" : "upload";
      const endpath = path || media.path;
      const url = `media/${endpoint}?path=${endpath}`;
      const data = new FormData();
      data.append("file", file);
      const res = await axios.post(url, data, {
        onUploadProgress: (progress) => {
          const { total, loaded } = progress;
          const totalSizeInMB = total / 1000000;
          const loadedSizeInMB = loaded / 1000000;
          const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
          dispatch(onProgress(uploadPercentage, file.upload_id));
        },
      });
      if (direct) {
        return res.data.data;
      } else {
        dispatch({
          type: actionTypes.FILE_UPLOADED,
          file: res.data.data,
          upload_id: file.upload_id,
        });
      }
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      dispatch({
        type: actionTypes.UPLOADED_ERROR,
        upload_id: file.upload_id,
      });
      // throw error;
      const msg = error.message || <Ttext ns="media" text="file_load_err" />;
      dispatch(fireSnake("error", msg));
    }
  };
};

/**
 * Gallery
 */
export const getGalleries = (query) => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = `/media/gallery`;

      let counter = 1;
      for (const key in query) {
        let operator = counter > 1 ? "&" : "?";
        url += `${operator}${key}=${query[key]}`;
        counter++;
      }
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_GALLERIES, galleries: res.data.data });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getGallery = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/media/gallery/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addEditGallery = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = "/media/gallery";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      // dispatch({ type: actionTypes.SET_PRODUCTS, products: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchGalleryStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/media/gallery/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.GALLERY_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteGallery = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/media/gallery/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.GALLERY_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
