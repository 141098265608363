import React from "react";

// Products
const Products = React.lazy(() => import("../views/Products/Products"));
const AddEditProduct = React.lazy(() => import("../views/Products/AddEdit"));
const ViewProduct = React.lazy(() => import("../views/Products/View"));
// Options
const Options = React.lazy(() => import("../views/Options/List"));
const AddEditOption = React.lazy(() => import("../views/Options/AddEdit"));

// Coupons
const Coupons = React.lazy(() => import("../views/Coupons/Coupons"));
const AddEditCoupon = React.lazy(() => import("../views/Coupons/AddEdit"));

const storeRoutes = [
  {
    path: "/store/products",
    component: Products,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: ["/store/products/add", "/store/products/:id/edit"],
    component: AddEditProduct,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/store/products/:id",
    component: ViewProduct,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/store/options",
    component: Options,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: ["/store/options/add", "/store/options/edit/:id"],
    component: AddEditOption,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/store/coupons",
    component: Coupons,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: ["/store/coupons/add", "/store/coupons/edit/:id"],
    component: AddEditCoupon,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
];

export default storeRoutes;
