import React from "react";
import { Paper } from "@material-ui/core";
import TableSkeleton from "../../common/TableSkeleton";
import { Chart } from "react-google-charts";
import i18n from "../../../i18n";

const BarChart = ({ data, vLabel, hlabel, title }) => {
  let _data = data;
  if (_data[0].length !== _data[1]?.length) {
    const emptyData = ["", 0];
    _data = [["v", "h"], ...Array.from("0".repeat(5)).map((abc) => emptyData)];
  }

  return (
    <Paper className="xp fullheight">
      <Chart
        width="100%"
        height="300px"
        // style={{ height: "100%" }}
        chartType="BarChart"
        loader={<TableSkeleton row={1} col={1} />}
        data={_data}
        options={{
          title,
          titleTextStyle: {
            fontSize: 20,
          },
          chartArea: { left: "25%" },
          // bar: { groupWidth: "60%" },
          legend: {
            position: "none",
          },
          hAxis: {
            title: hlabel,
          },
          vAxis: {
            title: vLabel,
            minValue: 0,
          },
          seriesType: "bars",
          animation: {
            duration: 1000,
            easing: "out",
            startup: true,
          },
        }}
        chartLanguage={i18n.language}
      />
    </Paper>
  );
};

export default BarChart;
