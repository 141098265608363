import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.REVIEWS_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.REVIEWS_ERRORS,
    errors: err,
  };
};

let timeout;
export const getReviews = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const url = `/review` + query;
      clearTimeout(timeout);
      const promise = new Promise((resolve) => {
        timeout = setTimeout(async () => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;
      dispatch({ type: actionTypes.SET_REVIEWS, reviews: res.data.data });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const switchReviewStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/review/${id}`;
      const res = await axios.put(url, { status });
      dispatch({ type: actionTypes.REVIEW_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
