import React, { Fragment } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@material-ui/core";

const TableSkeleton = ({
  row = 4,
  col = 1,
  height = 100,
  lineOnly,
  squareOnly,
  p = 1,
}) => {
  const rows = "r".repeat(row);
  const arr = rows.split("");
  return (
    <Grid container>
      {arr.map((a, i) => (
        <Grid
          item
          className="my"
          style={{ padding: `${p}rem` }}
          key={i}
          sm={12 / col}
          xs={12}
        >
          {squareOnly ? (
            <Skeleton variant="rect" height={height} />
          ) : (
            <Fragment>
              {!lineOnly && (
                <>
                  <Skeleton variant="rect" height={height} />
                  <Skeleton variant="text" />
                </>
              )}
              <Skeleton variant="text" />
              <Skeleton variant="text" width="80%" />
            </Fragment>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default TableSkeleton;
