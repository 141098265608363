import * as actionsTypes from "../../actions/types";

const initialState = {
  generals: [],
  normalized_generals: null,
  shippings: [],
  payments: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.SETTING_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case actionsTypes.SET_GENERALS:
      const normalized_generals = {};
      for (const gen of action.generals) {
        normalized_generals[`${gen.code}_${gen.key_id}`] = gen.value;
      }
      return {
        ...state,
        generals: action.generals,
        normalized_generals,
        loading: false,
      };

    case actionsTypes.SET_SHIPPINGS:
      return {
        ...state,
        shippings: action.shippings,
        loading: false,
      };
    case actionsTypes.SET_PAYMENTS:
      return {
        ...state,
        payments: action.payments,
        loading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };

    case actionsTypes.SETTING_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
