import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.CURRENCY_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.CURRENCY_ERRORS,
    errors: err,
  };
};

export const getCurrencies = () => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = `/currency`;

      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_CURRENCIES, currencies: res.data.data });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const getCurrency = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/currency/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addEditCurrency = (body, isEdit) => {
  return async (dispatch, getState) => {
    dispatch(itemLoading());

    try {
      let url = "/currency";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }

      // Update globals if needed
      const currency = res.data.data;
      const { auth } = getState();
      if (currency.is_primary > 0) {
        dispatch({
          type: actionTypes.SET_SETTINGS,
          settings: { ...auth.settings, currency: currency.code },
        });
      }

      return currency;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchCurrencyStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/currency/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.CURRENCY_SWITCH, id, status });

      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteCurrency = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/currency/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.CURRENCY_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
