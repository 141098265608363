import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getChartsStat } from "./../../store/actions";
import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import TableSkeleton from "./../common/TableSkeleton";
import { useTranslation } from "react-i18next";
import i18 from "./../../i18n";
import SelectInput from "./../Inputs/SelectInput";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { arSA } from "date-fns/locale";
import SalesChart from "./components/SalesChart";
import GeoChart from "./components/GeoChart";
import Globals from "../../util/globals";
import BarChart from "./components/BarChart";
import { getObjPropByString, getOrderColors } from "../../util/generals";
import PieChart from "./components/PieChart";

let timeout;

const ChartsData = ({
  queryType = "store",
  initialRange = "monthly",
  initialDate = new Date().getFullYear(),
  dataKey = "sales",
}) => {
  const [charts, setCharts] = useState(null);
  const [dateType, setDateType] = useState({
    type: initialRange, // "yearly" | "monthly" | "daily"
    date: `${initialDate || ""}`,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [region, setRegion] = useState(145);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const fetchCharts = useCallback(async () => {
    try {
      const { type, date } = dateType;
      let args = { monthly: "", daily: "" };
      if (type === "monthly") {
        args = { monthly: date, daily: "" };
      } else if (type === "daily") {
        args = { monthly: "", daily: date };
      }
      args.type = queryType;
      const ch = await dispatch(getChartsStat(args));
      setCharts(ch);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [dispatch, dateType, queryType]);

  useEffect(() => {
    timeout = setTimeout(() => {
      fetchCharts();
    }, 0);
    return () => clearTimeout(timeout);
  }, [fetchCharts]);

  return loading ? (
    <TableSkeleton row={2} col={2} />
  ) : !loading && error ? (
    <Paper className="p-1 center">
      <Typography color="error">{error.message}</Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setLoading(true);
          setError(false);
          fetchCharts();
        }}
      >
        {t("common:tryagain")}
      </Button>
    </Paper>
  ) : (
    <Grid container spacing={2}>
      {/* Selectors */}
      <Grid item xs={12}>
        <Container maxWidth="sm">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SelectInput
                options={[
                  {
                    value: "yearly",
                    text: t("common:yearly"),
                  },
                  {
                    value: "monthly",
                    text: t("common:monthly"),
                  },
                  {
                    value: "daily",
                    text: t("common:daily"),
                  },
                ]}
                name="parent_id"
                label={t("common:period")}
                getResult={(value) => {
                  setDateType((prev) => {
                    let date = prev.date.split(",")[0];
                    if (value === "daily") {
                      date = `${date},${new Date().getMonth() + 1}`;
                    }
                    return {
                      date: date,
                      type: value,
                    };
                  });
                }}
                value={dateType.type}
              />
            </Grid>
            {dateType.type !== "yearly" && (
              <Grid item xs={6}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={i18.language === "ar" ? arSA : ""}
                >
                  <DatePicker
                    value={
                      dateType.type === "monthly" || dateType.type === "yearly"
                        ? new Date(dateType.date, 1, 1)
                        : new Date(
                            dateType.date.split(",")[0],
                            dateType.date.split(",")[1] - 1
                          )
                    }
                    label={t(`common:choose_${dateType.type}`)}
                    onChange={(e) => {
                      setDateType((prev) => {
                        let date = `${new Date(e).getFullYear()}`;
                        if (prev.type === "daily") {
                          date = `${date},${new Date(e).getMonth() + 1}`;
                        }
                        return {
                          ...prev,
                          date: date,
                        };
                      });
                    }}
                    style={{ width: "100%" }}
                    inputVariant="outlined"
                    cancelLabel={t("common:cancel")}
                    okLabel={t("common:confirm")}
                    format={dateType.type === "daily" ? "yyyy MMMM" : "yyyy"}
                    views={
                      dateType.type === "daily" ? ["year", "month"] : ["year"]
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            )}
          </Grid>
        </Container>
      </Grid>

      {/* Sales Chart */}
      <Grid item sm={6} xs={12}>
        <SalesChart
          data={charts.sales}
          dateType={dateType}
          vLabel={` ${Globals.settings.currency || ""}  `}
          title={t("common:sales")}
        />
      </Grid>
      {/* Order Statuses Chart */}
      <Grid item sm={6} xs={12}>
        <BarChart
          data={[
            ["status", t("common:count"), { role: "style" }],
            ...charts.statuses.map((s, i) => [
              s.vAxis + "",
              +s.value,
              getObjPropByString(theme.palette, getOrderColors(s.status_id)),
            ]),
          ]}
          title={t("common:status_chart_title")}
          vLabel={t("common:status")}
          hlabel={` ${t("common:count")}  `}
        />
      </Grid>
      {/* Map chart */}
      {queryType === "store" && (
        <Grid item sm={6} xs={12}>
          <GeoChart
            data={charts.geochart}
            region={region}
            setRegion={setRegion}
          />
        </Grid>
      )}
      {/* Payment Methods */}
      <Grid item sm={6} xs={12}>
        <PieChart
          data={[
            ["group", t("common:count"), "tooltip"],
            ...charts.payment_methods.map((s, i) => [
              t(`settings:${s.vAxis}`),
              +s.value,
              20,
            ]),
          ]}
          title={t("common:payment_methods")}
        />
      </Grid>
    </Grid>
  );
};

export default ChartsData;
