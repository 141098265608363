import React from "react";
import Login from "../views/Auth/Login";
//ForgotPass
const ForgotPassword = React.lazy(() => import("../views/Auth/ForgotPassword"));

const authRoutes = [
  {
    path: "/forgotpassword/:email/:token",
    component: ForgotPassword,
  },
  {
    path: "/",
    component: Login,
  },
];

export default authRoutes;
