import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getRanksStat } from "../../../store/actions";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import TableSkeleton from "../../common/TableSkeleton";
import { useTranslation } from "react-i18next";
import RankList from "../components/RankList";

let timeout;

const RanksData = () => {
  const [ranks, setRanks] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchRanks = useCallback(async () => {
    try {
      const _ranks = await dispatch(getRanksStat("store"));
      setRanks(_ranks);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    timeout = setTimeout(() => {
      fetchRanks();
    }, 0);
    return () => clearTimeout(timeout);
  }, [fetchRanks]);

  return loading ? (
    <TableSkeleton row={3} col={3} />
  ) : !loading && error ? (
    <Paper className="p-1 center">
      <Typography color="error">{error.message}</Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setLoading(true);
          setError(false);
          fetchRanks();
        }}
      >
        {t("common:tryagain")}
      </Button>
    </Paper>
  ) : (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <Paper>
          <RankList
            data={ranks.views}
            headline={t("common:most_viewed")}
            wAvatar
          />
        </Paper>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Paper>
          <RankList
            data={ranks.sold}
            headline={t("common:most_sold")}
            wAvatar
          />
        </Paper>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Paper>
          <RankList data={ranks.loyals} headline={t("common:most_loyal")} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RanksData;
