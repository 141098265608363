// import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";

export const getNumbersStat = (type) => {
  return async (dispatch) => {
    try {
      let url = `/statistics/numbers?type=${type || ""}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};

export const getChartsStat = ({ monthly, daily, type }) => {
  return async (dispatch) => {
    try {
      let url = `/statistics/charts?monthly=${monthly}&daily=${daily}&type=${type}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};

export const getRanksStat = (type) => {
  return async (dispatch) => {
    try {
      let url = `/statistics/ranks?type=${type}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
