import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";
import { scrollIntoErrorNode } from "../../../util/generals";

const loading = () => {
  return {
    type: actionTypes.PRODUCTS_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  scrollIntoErrorNode();
  return {
    type: actionTypes.PRODUCTS_ERRORS,
    errors: err,
  };
};

export const getProducts = (query) => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = "/products" + query;
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_PRODUCTS, products: res.data.data });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getProduct = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/products/${id}`;

      const res = await axios.get(url);

      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addEditProduct = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = "/products";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      dispatch({ type: actionTypes.UNLOAD });
      // dispatch({ type: actionTypes.SET_PRODUCTS, products: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const switchProductStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/products/${id}`;
      await axios.patch(url, { status });
      dispatch({ type: actionTypes.PRODUCT_SWITCH, id, status });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteProduct = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/products/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.DELETE_PRODUCT, id: res.data.data });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

let timeout;
export const productsAutocomplete = (q) => {
  return async (dispatch) => {
    dispatch(loading());
    clearTimeout(timeout);

    try {
      let url = "/products/autocomplete?q=";
      if (q) {
        url += q;
      }
      const promise = new Promise((resolve, reject) => {
        timeout = setTimeout(async () => {
          resolve(axios.get(url));
        }, 1000);
      });
      const res = await promise;
      dispatch({ type: actionTypes.UNLOAD });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
