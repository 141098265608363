import * as actionsTypes from "../../actions/types";
import Globals from "../../../util/globals";

const initialState = {
  isAuth: false,
  token: "",
  role: "",
  username: "",
  expiresIn: "",
  refresh: "",
  locale: "ar",
  settings: {},
  loading: false,
  appLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.AUTH_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.APP_LOADING:
      return {
        ...state,
        appLoading: true,
        errors: null,
      };
    case actionsTypes.LOGIN:
      return {
        ...state,
        loading: false,
        appLoading: false,
        isAuth: true,
        ...action,
      };
    case actionsTypes.SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case actionsTypes.SET_SETTINGS:
      Globals.settings = action.settings;
      return {
        ...state,
        settings: action.settings,
      };

    case actionsTypes.SET_SUPPORTED_LANGS:
      const newSettigns = {
        ...state.settings,
        languages: action.languages,
      };
      Globals.settings = newSettigns;

      return {
        ...state,
        settings: newSettigns,
      };

    case actionsTypes.AUTH_ERRORS:
      return {
        ...state,
        loading: false,
        appLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
      };
    case actionsTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
export default reducer;
