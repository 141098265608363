import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";
import { scrollIntoErrorNode } from "../../../util/generals";

const loading = () => {
  return {
    type: actionTypes.OPTIONS_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  scrollIntoErrorNode();
  return {
    type: actionTypes.OPTIONS_ERRORS,
    errors: err,
  };
};
let timeout;
export const getOptions = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    clearTimeout(timeout);
    try {
      const url = `/option` + (query || "");
      const promise = await new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;
      dispatch({ type: actionTypes.SET_OPTIONS, options: res.data.data });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const getOption = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/option/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addEditOption = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = "/option";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      // dispatch({ type: actionTypes.SET_PRODUCTS, products: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchOptionStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/option/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.OPTIONS_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteOption = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/option/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.OPTIONS_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
let _timeout;
export const optionsAutocomplete = (q) => {
  return async (dispatch) => {
    try {
      clearTimeout(_timeout);
      dispatch(itemLoading());
      let url = "/option/autocomplete?q=";
      if (q) {
        url += q;
      }
      const promise = new Promise((resolve, reject) => {
        _timeout = setTimeout(() => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
