import React from "react";

// Settigns
const GeneralSettings = React.lazy(() =>
  import("../views/Settings/Generals/Generals")
);
const Shippings = React.lazy(() =>
  import("../views/Settings/Shipping/Shipping")
);
const Payment = React.lazy(() => import("../views/Settings/Payment/Payment"));
const Languages = React.lazy(() =>
  import("../views/Settings/Languages/Languages")
);
const AddEditLanguage = React.lazy(() =>
  import("../views/Settings/Languages/AddEdit")
);
const Currencies = React.lazy(() =>
  import("../views/Settings/Currency/Currency")
);
const AddEditCurrency = React.lazy(() =>
  import("../views/Settings/Currency/AddEdit")
);
const Taxes = React.lazy(() => import("../views/Settings/Tax/Tax"));
const AddEditTax = React.lazy(() => import("../views/Settings/Tax/AddEdit"));
const Admins = React.lazy(() => import("../views/Settings/Admins/Admins"));
const AddEditAdmins = React.lazy(() =>
  import("../views/Settings/Admins/AddEdit")
);

const settingsRoutes = [
  {
    path: "/settings/generals",
    component: GeneralSettings,
    configs: { exact: true },
    role: ["owner", "administrator"],
  },
  {
    path: "/settings/shippings",
    component: Shippings,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: "/settings/payments",
    component: Payment,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: "/settings/languages",
    component: Languages,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: ["/settings/languages/add", "/settings/languages/edit/:id"],
    component: AddEditLanguage,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/settings/currency",
    component: Currencies,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: ["/settings/currency/add", "/settings/currency/edit/:id"],
    component: AddEditCurrency,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/settings/tax",
    component: Taxes,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: ["/settings/tax/add", "/settings/tax/edit/:id"],
    component: AddEditTax,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/settings/admins",
    component: Admins,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: ["/settings/admins/add", "/settings/admins/edit/:id"],
    component: AddEditAdmins,
    configs: { exact: true },
    role: ["owner", "administrator"],
  },
];

export default settingsRoutes;
