import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { scrollIntoErrorNode } from "../../util/generals";

const loading = () => {
  return {
    type: actionTypes.CUSTOMERS_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  scrollIntoErrorNode();
  return {
    type: actionTypes.CUSTOMERS_ERRORS,
    errors: err,
  };
};

export const getCustomers = (query = "") => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const url = `/customer` + query;
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_CUSTOMERS, customers: res.data.data });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getCustomer = (id) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const url = `/customer/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const addEditCustomer = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(itemLoading());

    try {
      let url = "/customer";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getCustomerOrders = (id, query) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      const url = `/customer/${id}/orders` + (query || "");
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getCustomerGroups = (query) => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = `/customer/group`;
      const res = await axios.get(url);
      dispatch({
        type: actionTypes.SET_CUSTOMER_GROUPS,
        customer_groups: res.data.data,
      });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const switchCustomersStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/customer/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.CUSTOMERS_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      // dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
