import * as actionsTypes from "../../actions/types";

const initialState = {
  taxes: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.TAX_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_TAXES:
      return {
        ...state,
        taxes: action.taxes,
        loading: false,
      };
    case actionsTypes.TAX_SWITCH:
      const currTax = state.taxes;
      const id = action.id;
      const status = action.status;
      const updatedTaxes = currTax.map((tax) => {
        if (tax.tax_id === id) {
          return {
            ...tax,
            status: status,
          };
        }
        return tax;
      });
      return {
        ...state,
        taxes: updatedTaxes,
        itemLoading: false,
      };
    case actionsTypes.TAX_DELETE:
      const thisTaxes = state.taxes;
      const fileredTaxes = thisTaxes.filter((tax) => tax.tax_id !== action.id);
      return {
        ...state,
        taxes: fileredTaxes,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };

    case actionsTypes.TAX_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
