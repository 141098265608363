import { useTranslation } from "react-i18next";

const Ttext = ({ ns, text }) => {
  const { t } = useTranslation();
  if (ns) {
    return t(ns + ":" + text);
  }
  return t(text);
};

export default Ttext;
