import * as actionsTypes from "../actions/types";

const initialState = {
  reviews: {},
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.REVIEWS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_REVIEWS:
      return {
        ...state,
        reviews: {
          reviews: action.reviews.reviews,
          pagination: action.reviews.pagination,
        },
        loading: false,
      };
    case actionsTypes.REVIEW_SWITCH:
      const currRevs = state.reviews.reviews;
      const id = action.id;
      const status = action.status;
      const updatedRevs = currRevs.map((review) => {
        if (review.review_id === id) {
          return {
            ...review,
            status,
          };
        }
        return review;
      });
      return {
        ...state,
        reviews: {
          ...state.reviews,
          reviews: updatedRevs,
        },
      };
    case actionsTypes.REVIEWS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
