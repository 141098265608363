import React from "react";

// Orders
const Purchases = React.lazy(() => import("../views/Orders/Orders"));
const ViewPurchases = React.lazy(() => import("../views/Orders/View"));

const orderRoutes = [
  {
    path: "/orders",
    component: Purchases,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/orders/:id",
    component: ViewPurchases,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
];

export default orderRoutes;
