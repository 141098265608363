import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { scrollIntoErrorNode } from "../../util/generals";

const loading = () => {
  return {
    type: actionTypes.EVENT_LOADING,
  };
};
// const itemLoading = () => {
//   return {
//     type: actionTypes.ITEM_LOADING,
//   };
// };
const setErrors = (err) => {
  scrollIntoErrorNode();
  return {
    type: actionTypes.EVENT_ERRORS,
    errors: err,
  };
};

let timeout;
export const getEvents = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/event` + query;
      clearTimeout(timeout);
      const promise = new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;

      const { events, resources } = res.data.data;
      for (let event of events) {
        event.start = new Date(event.start);
        event.end = new Date(event.end);
      }

      dispatch({ type: actionTypes.SET_EVENTS, events, resources });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getEvent = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/event/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addEditEvent = (body, isEdit) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      let url = "/event";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
        dispatch({ type: actionTypes.EDIT_EVENT, event: res.data.data });
      } else {
        res = await axios.post(url, body);
        dispatch({ type: actionTypes.ADD_EVENT, event: res.data.data });
      }
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const deleteEvent = (id) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      let url = `/event/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.EVENT_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const donePostponeEvent = (id, body, deleteUpdate) => {
  return async (dispatch) => {
    try {
      let url = `/event/action/${id}`;
      const res = await axios.patch(url, body);
      const { current, newEvent } = res.data.data;
      if (newEvent) {
        dispatch({ type: actionTypes.ADD_EVENT, event: newEvent });
      }

      if (deleteUpdate) {
        /**
         * Why remove then add instead of update?
         * This is related to the @aldabil/react-shceduler component
         * It seems it wont re-render if fields change with still having same event_id?
         * //TODO: investigate
         */
        dispatch({ type: actionTypes.EVENT_DELETE, id: current.event_id });
        dispatch({ type: actionTypes.ADD_EVENT, event: current });
      } else {
        dispatch({ type: actionTypes.EDIT_EVENT, event: current });
      }

      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const addEventHistory = (body, id) => {
  return async (dispatch) => {
    try {
      let url = `/event/${id}/history`;
      const res = await axios.post(url, body);
      dispatch({
        type: actionTypes.EVENT_HISTORY,
        id: id,
        history: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
