import React, { useCallback } from "react";
import IsVisible from "./IsVisible";
import { loadScriptAsync } from "../../util/generals";
import Globals from "../../util/globals";

const WithRecaptcha = ({ children, onLoading, errors }) => {
  const loadScript = useCallback(async () => {
    try {
      if (onLoading) onLoading(true);
      const url = `https://www.google.com/recaptcha/api.js?render=${Globals.RECAPTCHA_KEY}`;
      await loadScriptAsync("recaptcha_script", url);
    } finally {
      if (onLoading) onLoading(false);
    }
  }, [onLoading]);

  // const _isError = error || isError(errors?.data, "recaptcha");

  return (
    <IsVisible
      onVisible={(visible) => {
        if (visible) {
          loadScript();
        }
      }}
    >
      <form>{children}</form>
    </IsVisible>
  );
};

export default WithRecaptcha;
