import * as actionsTypes from "../../actions/types";

const initialState = {
  admins: [],
  notifications: {},
  top_notifications: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.ADMINS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_ADMINS:
      return {
        ...state,
        admins: action.admins,
        loading: false,
      };
    case actionsTypes.SET_NOTIFS:
      return {
        ...state,
        notifications: action.notifications,
        loading: false,
      };
    case actionsTypes.SET_TOP_NOTIFS:
      return {
        ...state,
        top_notifications: action.notifications?.notifications || [],
        loading: false,
      };
    case actionsTypes.CLEAR_NOTIF:
      let updatedNotifs = [];
      const currNotifs = state.top_notifications || [];
      if (action.notif.type === "event" && !action.onlyOne) {
        updatedNotifs = currNotifs.filter((not) => not.type !== "event");
      } else {
        updatedNotifs = currNotifs.filter((not) => not.id !== action.notif.id);
      }
      return {
        ...state,
        top_notifications: updatedNotifs,
      };
    case actionsTypes.ADMINS_SWITCH:
      const currAdmins = state.admins;
      const id = action.id;
      const status = action.status;
      const updatedAdmins = currAdmins.map((admin) => {
        if (admin.admin_id === id) {
          return {
            ...admin,
            status: status,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
        itemLoading: false,
      };
    case actionsTypes.ADMINS_DELETE:
      const thisAdmins = state.admins;
      const fileredAdmins = thisAdmins.filter(
        (admin) => admin.admin_id !== action.id
      );
      return {
        ...state,
        admins: fileredAdmins,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };

    case actionsTypes.ADMINS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
