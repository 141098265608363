import React from "react";
import { Container, Grid } from "@material-ui/core";
import StoreDashboard from "../../components/Dashboard/Store/StoreDashboard";

const Dashborad = () => {
  return (
    <Container maxWidth="md" style={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <StoreDashboard />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashborad;
