import { setLocaleAuthHeader } from "../util/axiosAuth";

const reducer = (state, action) => {
  switch (action.type) {
    // case "TOGGLE_DARK":
    //   return {
    //     ...state,
    //     palette: {
    //       ...state.palette,
    //       type: action.payload
    //     }
    //   };
    case "SET_PALETTE":
      return {
        ...state,
        palette: {
          ...state.palette,
          ...getPalette(action.template),
        },
        css: {
          ...getCSSThemeBG(action.template),
        },
      };
    case "TOGGLE_LANG":
      document.documentElement.lang = action.lang;
      document.documentElement.dir = action.dir;
      setLocaleAuthHeader(action.lang);
      return {
        ...state,
        direction: action.dir,
        language: action.lang,
      };
    default:
      return state;
  }
};

//Helpers
export const getPalette = (template) => {
  switch (template) {
    case "main":
      return {
        primary: {
          main: "#c27421",
        },
        secondary: {
          main: "#1F1E1E",
        },
        warning: {
          main: "#2C5BC9",
        },
        text: {
          disabled: "#737373",
        },
        info: {
          main: "#EAEAEA",
        },
        error: {
          main: "#d32f2f",
        },
        success: {
          main: "#4CAF50",
        },
        // type: "dark",
      };
    // case "second":
    //   return {
    //     primary: {
    //       main: "#045d7a",
    //     },
    //     secondary: {
    //       main: "#D67044",
    //     },
    //   };
    // case "third":
    //   return {
    //     primary: {
    //       main: "#412234",
    //     },
    //     secondary: {
    //       main: "#996888",
    //     },
    //   };
    default:
      break;
  }
};
export default reducer;
export const getCSSThemeBG = (tempName) => {
  let template = tempName;
  let primary = "bg-primary";
  let primary_light = "bg-primary-light";
  let primary_light_color = "rgb(194, 255, 218)";
  let secondary = "bg-secondary";
  let gradient_primary = "bg-primary-gradient";
  let gradient_secondary = "bg-secondary-gradient";
  let gradient_third = "bg-third-gradient";
  switch (tempName) {
    case "main":
      template = "main";
      primary = "bg-primary";
      primary_light = "bg-primary-light";
      primary_light_color = "#F3E3E4";
      secondary = "bg-secondary";
      gradient_primary = "bg-primary-gradient";
      gradient_secondary = "bg-secondary-gradient";
      gradient_third = "bg-third-gradient";
      break;
    case "second":
      template = "second";
      primary = "bg-second-primary";
      primary_light = "bg-second-primary-light";
      primary_light_color = "#d1deea";
      secondary = "bg-second-secondary";
      gradient_primary = "bg-second-primary-gradient";
      gradient_secondary = "bg-second-secondary-gradient";
      gradient_third = "bg-second-third-gradient";
      break;
    case "third":
      template = "third";
      primary = "bg-third-primary";
      primary_light = "bg-third-primary-light";
      primary_light_color = "#ffebf7";
      secondary = "bg-third-secondary";
      gradient_primary = "bg-third-primary-gradient";
      gradient_secondary = "bg-third-secondary-gradient";
      gradient_third = "bg-third-third-gradient";
      break;
    default:
      break;
  }

  return {
    template,
    primary,
    primary_light,
    primary_light_color,
    secondary,
    gradient_primary,
    gradient_secondary,
    gradient_third,
  };
};
