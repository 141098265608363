import React, { useEffect, useContext, Suspense } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import themeContext from "./theme/themeContext";
import { Route, Switch, withRouter } from "react-router-dom";
import GlobalSnakebar from "./components/UI/GlobalSnakebar/SnakeBar";
import Loader from "./views/Loader";
import { useDispatch, useSelector } from "react-redux";
import { authRoutes, publicRoutes } from "./routes";
import { useTranslation } from "react-i18next";
import { initApp } from "./store/actions";
import Layout from "./components/Layout/Layout";
import { CssBaseline } from "@material-ui/core";

const App = () => {
  const { theme } = useContext(themeContext);
  const dispatch = useDispatch();
  const { isAuth, appLoading, role } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(initApp()); //Auto auth
  }, [dispatch]);

  if (appLoading) {
    return <Loader text={t("common:login")} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<Loader text={t("common:loadingggg")} />}>
        <Switch>
          {isAuth
            ? authRoutes.map((route) => {
                if (
                  route.role?.length &&
                  !route.role.some((r) => role.includes(r))
                ) {
                  return "";
                }
                const Comp = route.component;
                return (
                  <Route
                    key={route.path}
                    {...route.configs}
                    path={route.path}
                    render={(routeProps) => (
                      <Layout>
                        <Comp {...routeProps} {...route.props} />
                      </Layout>
                    )}
                  />
                );
              })
            : publicRoutes.map((route) => (
                <Route
                  key={route.path}
                  {...route.configs}
                  path={route.path}
                  component={route.component}
                />
              ))}
        </Switch>
      </Suspense>
      <GlobalSnakebar />
    </ThemeProvider>
  );
};

export default withRouter(App);
