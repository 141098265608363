import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { queryUrlArrage } from "../../util/generals";

const loading = () => {
  return {
    type: actionTypes.TAXONOMY_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.TAXONOMY_ERRORS,
    errors: err,
  };
};
let timeout = {};
export const getTaxonomies = (query, type) => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = `/taxonomy?type=${type}&expand=children`;
      for (const key in query) {
        url += `&${key}=${query[key]}`;
      }
      clearTimeout(timeout[type]);
      const promise = new Promise((resolve) => {
        timeout[type] = setTimeout(() => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;
      dispatch({ type: actionTypes.SET_TAXONOMY, taxonomies: res.data.data });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getParentTaxonomies = (type) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/taxonomy?type=${type}`;
      const res = await axios.get(url);
      return res.data.data.taxonomies;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getTaxonomy = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/taxonomy/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addEditTaxonomy = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = "/taxonomy";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }

      // dispatch({ type: actionTypes.SET_PRODUCTS, products: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchTaxonomyStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/taxonomy/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.TAXONOMY_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteTaxonomy = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/taxonomy/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.TAXONOMY_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const taxonomyAutocomplete = ({ q, type, parents = true, of }) => {
  return async (dispatch) => {
    dispatch(loading());
    clearTimeout(timeout[type]);
    try {
      const query = queryUrlArrage({ q, type, parents, of });
      let url = `/taxonomy/autocomplete${query}`;
      const promise = new Promise((resolve, reject) => {
        timeout[type] = setTimeout(async () => {
          resolve(axios.get(url));
        }, 1000);
      });
      const res = await promise;
      dispatch({ type: actionTypes.UNLOAD });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
