import * as actionsTypes from "../actions/types";

const initialState = {
  customers: {},
  customer_groups: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.CUSTOMERS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          customers: action.customers.customers,
          pagination: action.customers.pagination,
        },
        loading: false,
      };
    case actionsTypes.CUSTOMERS_SWITCH:
      const currCustomers = state.customers.customers;
      const id = action.id;
      const status = action.status;
      const updatedCustomers = currCustomers.map((customer) => {
        if (customer.customer_id === id) {
          return {
            ...customer,
            status: status,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: {
          ...state.customers,
          customers: updatedCustomers,
        },
      };
    case actionsTypes.SET_CUSTOMER_GROUPS:
      return {
        ...state,
        customer_groups: action.customer_groups,
      };
    case actionsTypes.CUSTOMERS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
