import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  ListSubheader,
  Checkbox,
  useTheme,
  Chip,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SelectSpinner from "../UI/Spinners/SelectSpinner";
import { useTranslation } from "react-i18next";

const SelectInput = ({
  options,
  value,
  name,
  required,
  getResult,
  label,
  errMsg,
  disabled,
  setTouched,
  helperText,
  serverError,
  variant,
  loading,
  multiple,
  placeholder,
  styles,
  optionClasses,
  id,
  onKeyDown,
  idSelector = "id",
  valueSelector = "value",
  formControlStyle,
  SelectDisplayProps,
  labelStyle,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [state, setState] = useState({
    touched: false,
    valid: !!value,
    errorMsg: errMsg ? errMsg : required ? t("common:required_err") : "",
  });
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    if (label) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [label]);
  useEffect(() => {
    if (setTouched) {
      // setState((prev) => {
      //   return { ...prev, touched: true };
      // });
      handleChange({ target: { value } });
    }
    // eslint-disable-next-line
  }, [setTouched]);

  useEffect(() => {
    if (serverError) {
      setState((prev) => {
        return {
          ...prev,
          valid: false,
          touched: true,
          errorMsg: serverError,
        };
      });
    }
  }, [serverError]);
  const handleTouched = () => {
    if (!state.touched) {
      setState((prev) => {
        return { ...prev, touched: true, errorMsg: errMsg || prev.errorMsg };
      });
    }
  };
  const handleChange = (e) => {
    let val = e.target.value;
    let isValid = true;
    let errorMsg = errMsg;
    if (required && (multiple ? !val.length : !val)) {
      isValid = false;
      errorMsg = t("common:required_err");
    }
    setState((prev) => {
      return { ...prev, touched: true, valid: isValid, errorMsg: errorMsg };
    });
    getResult(val, isValid, name);
  };
  return (
    <>
      <FormControl
        variant={variant || "outlined"}
        fullWidth
        error={(required && state.touched && !state.valid) || !!serverError}
        style={formControlStyle}
        disabled={disabled}
      >
        {label && (
          <InputLabel ref={inputLabel} style={labelStyle}>
            <Typography variant="body2">{`${label} ${
              required ? "**" : ""
            }`}</Typography>
          </InputLabel>
        )}
        <Select
          id={id || name}
          value={value}
          name={name}
          onBlur={handleTouched}
          onChange={handleChange}
          labelWidth={labelWidth}
          IconComponent={loading ? SelectSpinner : ExpandMoreIcon}
          multiple={!!multiple}
          classes={{ selectMenu: multiple === "chips" ? "chips_wrap" : "" }}
          renderValue={(selected) => {
            if (!selected || selected.length === 0) {
              return <em>{label || placeholder}</em>;
            }
            let text = [];
            if (multiple) {
              for (const opt of options) {
                if (selected.includes(opt[valueSelector])) {
                  text.push([opt.render || opt.text || opt.title]);
                }
              }
              if (multiple === "chips") {
                return text.map((t, i) => (
                  <Chip
                    key={`${t}_${i}`}
                    label={t}
                    className="xm"
                    color="primary"
                    size="small"
                    style={{ height: 18, fontSize: 11 }}
                  />
                ));
              } else {
                return text.join(",");
              }
            } else {
              for (const opt of options) {
                if (selected === opt[valueSelector])
                  text.push([opt.render || opt.text || opt.title]);
              }
              return text.join(",");
            }
          }}
          style={styles}
          onClose={onKeyDown}
          SelectDisplayProps={SelectDisplayProps}
        >
          {placeholder && (
            <MenuItem value={""} className={optionClasses}>
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options.map((op) =>
            op.subheader ? (
              <ListSubheader
                value=""
                key={op[idSelector] ? op[idSelector] : op[valueSelector]}
                className={`${optionClasses} bolder center`}
              >
                {op.render || op.text || op.title}
              </ListSubheader>
            ) : (
              <MenuItem
                value={op[valueSelector]}
                key={op[idSelector] ? op[idSelector] : op[valueSelector]}
                className={optionClasses}
              >
                {multiple && (
                  <Checkbox
                    checked={value.indexOf(op[valueSelector]) > -1}
                    color="primary"
                  />
                )}
                {op.render || op.title || op.text}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      <FormHelperText style={{ color: theme.palette.error.main }}>
        {state.touched && !state.valid && state.errorMsg}
      </FormHelperText>
      {/* {serverError && initialValue && (
        <FormHelperText style={{ color: theme.palette.error.main }}>
          {serverError}
        </FormHelperText>
      )} */}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
};

export default SelectInput;
