import React from "react";
// Communications
const PushNotifications = React.lazy(() =>
  import("../views/Communication/PushNotifications/PushNotifications")
);
const ContactForm = React.lazy(() =>
  import("../views/Communication/ContactForm/List")
);
const ContactFormView = React.lazy(() =>
  import("../views/Communication/ContactForm/View")
);

const communicationRoutes = [
  {
    path: "/communication/push",
    component: PushNotifications,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: "/communication/form",
    component: ContactForm,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/communication/form/:id",
    component: ContactFormView,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
];

export default communicationRoutes;
