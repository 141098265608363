import * as actionsTypes from "../../actions/types";

const initialState = {
  currencies: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.CURRENCY_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case actionsTypes.SET_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies,
        loading: false,
      };

    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };

    case actionsTypes.CURRENCY_SWITCH:
      const id = action.id;
      const status = action.status;
      const updatedCarrencies = state.currencies.map((carr) => {
        if (carr.currency_id === id) {
          return {
            ...carr,
            status: status,
          };
        }
        return carr;
      });
      return {
        ...state,
        currencies: updatedCarrencies,
        itemLoading: false,
      };
    case actionsTypes.CURRENCY_DELETE:
      const filteredCurrs = state.currencies.filter(
        (cuss) => cuss.currency_id !== action.id
      );
      return {
        ...state,
        currencies: filteredCurrs,
        itemLoading: false,
      };
    case actionsTypes.CURRENCY_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
