import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.ENTRY_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.ENTRY_ERRORS,
    errors: err,
  };
};

let timeout;
export const getEntries = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/contact`;
      let counter = 1;
      for (const key in query) {
        let operator = counter > 1 ? "&" : "?";
        url += `${operator}${key}=${query[key]}`;
        counter++;
      }
      clearTimeout(timeout);
      const promise = new Promise((resolve) => {
        timeout = setTimeout(async () => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;
      dispatch({ type: actionTypes.SET_ENTRY, entries: res.data.data });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const getEntry = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/contact/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const deleteEntry = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/contact/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.ENTRY_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
