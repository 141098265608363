import * as actionsTypes from "../../actions/types";

const initialState = {
  languages: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.LANGUAGE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case actionsTypes.SET_LANGUAGES:
      return {
        ...state,
        languages: action.languages,
        loading: false,
      };

    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };

    case actionsTypes.LANGUAGE_SWITCH:
      const currLangs = state.languages;
      const id = action.id;
      const status = action.status;
      const updatedLangs = currLangs.map((lng) => {
        if (lng.language_id === id) {
          return {
            ...lng,
            status: status,
          };
        }
        return lng;
      });
      return {
        ...state,
        languages: updatedLangs,
        itemLoading: false,
      };
    case actionsTypes.LANGUAGE_DELETE:
      const thisLngs = state.languages;
      const filteredLngs = thisLngs.filter(
        (lng) => lng.language_id !== action.id
      );
      return {
        ...state,
        languages: filteredLngs,
        itemLoading: false,
      };
    case actionsTypes.LANGUAGE_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
