import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const ButtonSpinner = ({ color, size, margin, styles }) => {
  return (
    <CircularProgress
      color={color || "primary"}
      size={size || "1rem"}
      style={{ margin, ...styles }}
    />
  );
};

export default ButtonSpinner;
