import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.SPECIALS_LOADING,
  };
};

const setErrors = (err) => {
  return {
    type: actionTypes.SPECIALS_ERRORS,
    errors: err,
  };
};

export const getSpecials = (id) => {
  return async (dispatch) => {
    dispatch(loading());

    try {
      let url = `/products/${id}/specials`;
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_SPECIALS, specials: res.data.data });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const addSpecial = (data) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const { product_id, price, deadline, status } = data;

      let url = `/products/${product_id}/specials`;
      const res = await axios.post(url, { price, deadline, status });
      dispatch({ type: actionTypes.SET_SPECIALS, specials: res.data.data });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const editSpecial = (data) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const { id, product_id, price, deadline, status } = data;

      let url = `/products/${product_id}/specials/${id}`;
      const res = await axios.put(url, { price, deadline, status });
      dispatch({ type: actionTypes.SET_SPECIALS, specials: res.data.data });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const deleteSpecial = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loading());
      const { specials } = getState();
      const { id, product_id } = data;

      let url = `/products/${product_id}/specials/${id}`;
      const res = await axios.delete(url);
      const _specials = specials.specials.filter((s) => s.id !== res.data.data);
      dispatch({ type: actionTypes.SET_SPECIALS, specials: _specials });
      // return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
