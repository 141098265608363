import React from "react";
import { Box, Typography, Button, useTheme, alpha } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { useTranslation } from "react-i18next";

const IconTextNotice = ({
  type,
  text,
  component,
  color = "primary",
  onAction,
  padding,
  fontSize,
  opacity,
  customIcon,
  materialIcon,
  iconStyles,
  textStyles,
  horizontal,
  wBG,
  actionText,
  actionIcon,
  actionSize = "medium",
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  let ICON;
  switch (type) {
    case "success":
      ICON = CheckCircleRoundedIcon;
      break;
    case "error":
      ICON = HighlightOffRoundedIcon;
      break;
    default:
      ICON = materialIcon || WarningRoundedIcon;
  }

  return (
    <Box
      padding={padding || 5}
      className="center fullwidth"
      style={{ background: wBG ? alpha(theme.palette[color].main, 0.1) : "" }}
    >
      <Box className={`${horizontal ? "flex align-items-center" : ""}`}>
        {customIcon ? (
          customIcon
        ) : (
          <ICON
            style={{
              color: theme.palette[color].main,
              fontSize: fontSize || 200,
              opacity: opacity || 0.1,
              ...iconStyles,
            }}
          />
        )}
        {text && (
          <Typography variant="body2" style={textStyles}>
            {text}
          </Typography>
        )}
        {component && component}
      </Box>
      {onAction && (
        <Box className="m">
          <Button
            onClick={onAction}
            style={{
              color: theme.palette[color].main,
              borderColor: theme.palette[color].light,
            }}
            variant="outlined"
            endIcon={actionIcon || <CachedIcon />}
            size={actionSize}
          >
            {actionText || t("common:tryagain")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default IconTextNotice;
