import React from "react";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import i18n from "../../i18n";

const LocaleArrow = (props) => {
  const { direction = "back", color, size, style } = props;

  const isRTL = i18n.dir(i18n.language) === "rtl";
  const BackArrow = isRTL
    ? ArrowForwardIosRoundedIcon
    : ArrowBackIosRoundedIcon;
  const ForwardArrow = isRTL
    ? ArrowBackIosRoundedIcon
    : ArrowForwardIosRoundedIcon;

  const Icon = direction === "back" ? BackArrow : ForwardArrow;

  return (
    <Icon
      {...props}
      color={color || "inherit"}
      fontSize={size || "default"}
      style={{ ...style }}
    />
  );
};

export default LocaleArrow;
