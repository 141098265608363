import { Box, Button, Paper, Typography, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const getColor = (_color, theme) => {
  switch (_color) {
    case "primary":
    case "secondary":
    case "success":
    case "error":
    case "info":
    case "warning":
      return {
        main: theme.palette[_color].main,
        light: theme.palette[_color].light,
        dark: theme.palette[_color].dark,
        contrast: theme.palette[_color].contrastText,
      };
    case "grey":
    default:
      return {
        main: theme.palette.grey[500],
        light: theme.palette.grey[300],
        dark: theme.palette.grey[700],
        contrast: theme.palette.getContrastText(theme.palette.grey[500]),
      };
  }
};

const SingleNumberData = ({ color = "primary", text, value, to, Icon }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { main, light, dark, contrast } = getColor(color, theme);

  const [hovered, setHovered] = useState(false);
  return (
    <Paper
      style={{ background: main }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        className={`flex align-items-center flex-justify-between flex-wrap`}
        style={{ padding: "1rem 1rem 0 1rem" }}
      >
        <Box className="flex align-items-center flex-wrap flex-justify-center center">
          <Icon
            style={{
              color: hovered ? light : dark,
              fontSize: 25,
              transition: "all 200ms linear",
            }}
          />
          <Typography className="bold" style={{ color: contrast }}>
            {t(`common:${text}`)}
          </Typography>
        </Box>
        <Paper
          style={{
            background: hovered ? light : dark,
            transition: "all 200ms linear",
            minWidth: 40,
          }}
          className="py center"
        >
          <Typography
            variant="caption"
            className="bold px"
            style={{ color: contrast }}
          >
            {value}
          </Typography>
        </Paper>
      </Box>
      <Box className="flex flex-justify-end align-items-end" height={30}>
        {to && (
          <Link to={to}>
            <Button
              size="small"
              style={{
                color: theme.palette[color].contrast,
                fontSize: 12,
                padding: "1px 0",
                borderRadius: 3,
              }}
            >
              {t("common:details")}
            </Button>
          </Link>
        )}
      </Box>
    </Paper>
  );
};

export default SingleNumberData;
