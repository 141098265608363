import React from "react";
import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SelectInput from "../../Inputs/SelectInput";
import { Chart } from "react-google-charts";
import TableSkeleton from "../../common/TableSkeleton";
import Globals from "../../../util/globals";
import i18n from "../../../i18n";

const GeoChart = ({ data, region, setRegion }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  if (!data) return "";
  return (
    <Paper className="xp">
      <Grid container spacing={1} className="p" alignItems="center">
        <Grid item xs={6}>
          <Typography align="center" variant="h4">
            {t("common:sales_map")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            options={[
              {
                value: 145,
                text: t("common:gulf"),
              },
              {
                value: "world",
                text: t("common:world"),
              },
            ]}
            label={t("common:region")}
            getResult={(value) => setRegion(value)}
            value={region}
          />
        </Grid>
      </Grid>
      <Chart
        width="100%"
        height="100%"
        style={{ height: 300 }}
        chartType="GeoChart"
        loader={<TableSkeleton row={1} col={1} />}
        data={[
          ["City", `${t("common:sales")}`],
          ...data.map((c) => [c.city, c.value]),
        ]}
        options={{
          region: region,
          displayMode: "markers",
          colorAxis: {
            colors: [theme.palette.primary.light, theme.palette.primary.dark],
          },
          keepAspectRatio: false,
        }}
        chartLanguage={i18n.language}
        mapsApiKey={Globals.settings.google_api}
        // controls={[
        //   {
        //     controlType: "CategoryFilter",
        //     options: {
        //       filterColumnIndex: 0,
        //       ui: {
        //         // labelStacking: "hori",
        //         label: "",
        //         caption: t("common:choose_city"),
        //         allowTyping: false,
        //         // allowMultiple: false,
        //       },
        //     },
        //   },
        // ]}
        rootProps={{ "data-testid": "3" }}
      />
    </Paper>
  );
};

export default GeoChart;
