import * as actionsTypes from "../../actions/types";

const initialState = {
  orders: {},
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.ORDERS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_ORDERS:
      return {
        ...state,
        orders: {
          orders: action.orders.orders,
          pagination: action.orders.pagination,
        },
        loading: false,
      };
    // case actionsTypes.ORDERS_DELETE:
    //   const thisOrder = state.orders.orders;
    //   const filteredOrders = thisOrder.filter(
    //     (ord) => ord.order_id !== action.id
    //   );
    //   return {
    //     ...state,
    //     orders: {
    //       ...state.orders,
    //       orders: filteredOrders,
    //     },
    //     itemLoading: false,
    //   };
    case actionsTypes.ORDERS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
