import React from "react";
import { Box, ButtonBase, Fade, Paper } from "@material-ui/core";
import { Fragment, useState } from "react";
import FileSkeleton from "../Media/FileSkeleton";
import ImagesPreview from "../Media/ImagesPreview";

const ImageWebp = ({
  file,
  width,
  height,
  styles,
  classes,
  radius,
  shadow,
  lightBox,
  slides = [],
  initialIdx,
  lazy = true,
}) => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const openLightbox = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <Fragment>
      {loading && (
        <Box
          padding={1}
          width={width || "100%"}
          height={height}
          overflow="hidden"
          style={{ ...styles }}
        >
          <FileSkeleton number={1} xs={12} height={height} />
        </Box>
      )}
      {file && (
        <ButtonBase
          component={shadow ? Paper : "div"}
          elevation={+shadow > 0 ? shadow : 1}
          style={{
            width: width || "100%",
            height: loading ? 0 : height,
            borderRadius: radius || 0,
            overflow: "hidden",
            display: "block",
            cursor: lightBox ? "pointer" : "unset",
            margin: "auto",
            ...styles,
          }}
          disableRipple={!lightBox}
          onClick={lightBox ? openLightbox : null}
        >
          <Fade in={!loading} timeout={700}>
            <picture>
              <source srcSet={file.webpPath} type="image/webp" />
              <source srcSet={file.path} type={file.mimetype} />
              <img
                alt={file.title}
                src={file.webpPath}
                width={width}
                height={!loading ? height : 0}
                style={{ display: "block", ...styles }}
                className={classes}
                loading={lazy ? "lazy" : "eager"}
                onLoad={() => setLoading(false)}
                onError={() => setLoading(false)}
              />
            </picture>
          </Fade>
        </ButtonBase>
      )}
      {lightBox && (
        <ImagesPreview
          open={open}
          onClose={() => setOpen(false)}
          images={slides.length ? slides : [file]}
          originalize
          initialIdx={initialIdx}
        />
      )}
    </Fragment>
  );
};

export default ImageWebp;
