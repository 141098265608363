import React from "react";

// Appointments
const Appointments = React.lazy(() =>
  import("../views/Appointments/Appointments")
);

const appointmentRoutes = [
  {
    path: "/appointments",
    component: Appointments,
    configs: { exact: true },
  },
];

export default appointmentRoutes;
