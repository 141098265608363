import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const SelectSpinner = ({ color }) => {
  return (
    <span className={"m"}>
      <CircularProgress size="1rem" />
    </span>
  );
};

export default SelectSpinner;
