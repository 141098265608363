import { combineReducers } from "redux";
import authReducer from "./admin/auth";
import productsReducer from "./shop/products";
import specialsReducer from "./shop/specials";
import taxonomyReducer from "./taxonomies";
import optionsReducer from "./shop/options";
import couponsReducer from "./shop/coupons";
import ordersReducer from "./shop/orders";
import customerReducer from "./customer";
import reviewReducer from "./review";
import eventReducer from "./events";
import mediaReducer from "./media";
import contactReducer from "./contact";
import snakebarReducer from "./globalsnakebar";
import settingsReducer from "./settings/settings";
import languagesReducer from "./settings/languages";
import currencyReducer from "./settings/currency";
import taxReducer from "./settings/tax";
import adminsReducer from "./admin/admins";
import profileReducer from "./admin/profile";

export default combineReducers({
  auth: authReducer,
  products: productsReducer,
  specials: specialsReducer,
  taxonomies: taxonomyReducer,
  options: optionsReducer,
  coupons: couponsReducer,
  orders: ordersReducer,
  customers: customerReducer,
  reviews: reviewReducer,
  events: eventReducer,
  media: mediaReducer,
  contact: contactReducer,
  snakebar: snakebarReducer,
  settings: settingsReducer,
  languages: languagesReducer,
  currency: currencyReducer,
  taxes: taxReducer,
  admins: adminsReducer,
  profile: profileReducer,
});
