import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const IsVisible = (props) => {
  const { onVisible, offset } = props;
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: offset,
  });

  useEffect(() => {
    if (inView) {
      onVisible(inView);
    }
    // eslint-disable-next-line
  }, [inView]);

  return <Box ref={ref}>{props.children}</Box>;
};

export default IsVisible;
