import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ChartsData from "../ChartsData";
import NumbersData from "./NumbersData";
import RanksData from "./RanksData";

const StoreDashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="border-lighter radius p-1 my-1">
        <Typography
          variant="h4"
          align="center"
          color="textSecondary"
          gutterBottom
        >
          {t("common:overview")}
        </Typography>
        <NumbersData />
      </div>
      <div className="border-lighter radius p-1 my-1">
        <Typography
          variant="h4"
          align="center"
          color="textSecondary"
          className="pb-1"
        >
          {t("common:charts")}
        </Typography>
        <ChartsData />
      </div>
      <div className="border-lighter radius p-1 my-1">
        <Typography
          variant="h4"
          align="center"
          color="textSecondary"
          className="py"
        >
          {t("common:ranks")}
        </Typography>
        <RanksData />
      </div>
    </div>
  );
};

export default StoreDashboard;
