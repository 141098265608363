import { useMediaQuery, useTheme } from "@material-ui/core";

export function WithMediaQuery(Component) {
  const ComponentWithMQ = (props) => {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const mdUp = useMediaQuery(theme.breakpoints.up("md"));
    return (
      <Component
        {...props}
        smDown={smDown}
        smUp={smUp}
        mdDown={mdDown}
        mdUp={mdUp}
      />
    );
  };

  return ComponentWithMQ;
}
